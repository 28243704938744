import { TempLayer } from './TempLayer';
import { PrecipLayer } from './PrecipLayer';
import { CDCLayer } from './CDCLayer';
import { EPTLayer } from './EPTLayer';
import { PressLayer } from './PressLayer';
import { HGTLayer } from './HGTLayer';
import { SSILayer } from './SSILayer';
import { KINXLayer } from './KINXLayer';
import { WindyLayer } from './WindyLayer';
import { SkyWindyLayer } from './SkyWindyLayer';
import { RHLayer } from './RHLayer';

export class LayerFactory {
  static init(element) {
    if (element.startsWith('TMP')) {
      return new TempLayer(element);
    }
    else if (element.startsWith('PRATE')) {
      let layer = new PrecipLayer(element);
      layer.f_scalingPixelValues = x => x * 3600;
      return layer;
    }
    else if (element.startsWith('P1HP') ||
              element.startsWith('APCP')) {
      return new PrecipLayer(element);
    }
    else if (element.startsWith('LCDC') || 
              element.startsWith('MCDC') || 
              element.startsWith('HCDC')) {
      return new CDCLayer(element);
    }
    else if (element.startsWith('EPT')) {
      return new EPTLayer(element);
    }
    else if (element.startsWith('PRMSL')) {
      return new PressLayer(element);
    }
    else if (element.startsWith('HGT')) {
      return new HGTLayer(element);
    }
    else if (element.startsWith('SSI')) {
      return new SSILayer(element);
    }
    else if (element.startsWith('KINX')) {
      return new KINXLayer(element);
    }
    else if (element.startsWith('WIND')) {
      return new WindyLayer(element);
    }
    else if (element.startsWith('SKYWIND')) {
      return new SkyWindyLayer(element);
    }
    else if (element.startsWith('RH')) {
      return new RHLayer(element);
    }
  }
}
