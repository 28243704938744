import  { GeotiffLayer } from './GeotiffLayer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export class PrecipLayer extends GeotiffLayer {
  createThresholdColor() {
    const colors = {
       //0.1 : '#f5f5ff',
       0.1 : '#b8ddff',
       1.0 : '#59a9ff',
       5.0 : '#4071ff',
      10.0 : '#fff840',
      20.0 : '#ffb340',
      30.0 : '#ff5e40',
      50.0 : '#c7408e',
    };
    const thresholds = Object.keys(colors).sort((a, b) => parseFloat(a) - parseFloat(b)); // ascending
    return { thresholds, colors };
  }
  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon,
      thresholdColor, 0.5, 0.5,
      ( value => this.Round(value, 1) ));
    return [color_layer, line_layer];
  }
}
