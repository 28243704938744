export class Util {
  
  static isSameArray(array1, array2) {
    return (array1.length == array2.length) && 
          array1.every(function(element, index) {
              return element === array2[index];
            });
  }

  static Luminance(color) {
    const cc = parseInt(color.slice(1), 16);
    const rgb = [cc >> 16 & 0xff, cc >> 8 & 0xff, cc & 0xff];
    return 0.298912*rgb[0] + 0.586611*rgb[1] + 0.114478*rgb[2];
  }

  static rgb2hsv(color) {
    const cc = parseInt(color.slice(1), 16);
    const rgb = [cc >> 16 & 0xff, cc >> 8 & 0xff, cc & 0xff];
    const v = rgb.reduce((a, b) => b < a ? a : b);
    const d = v - rgb.reduce((a, b) => a < b ? a : b);
    const s = v ? d / v : 0;
    let h = 0;
    if (s === 0)  h = 0; 
    else if (v === rgb[0])  h = (rgb[1] - rgb[2]) / d + (rgb[1] < rgb[2] ? 6 : 0);
    else if (v === rgb[1])  h = 2 + (rgb[2] - rgb[0]) / d;
    else                    h = 4 + (rgb[0] - rgb[1]) / d;
    h *= 60;
    return {
        h: h,
        s: s,
        v: v / 255,
    };
  }

  static complementaryColor(color) {
    const rgb = [parseInt(color.slice(1, 3), 16),
                parseInt(color.slice(3, 5), 16),
                parseInt(color.slice(5, 7), 16)];
    const max = rgb.reduce((a, b) => b < a ? a : b);
    const min = rgb.reduce((a, b) => a < b ? a : b);
    const sum = max + min;
    return '#' + 
            ('00'+(sum-rgb[0]).toString(16)).slice(-2) + 
            ('00'+(sum-rgb[1]).toString(16)).slice(-2) +
            ('00'+(sum-rgb[2]).toString(16)).slice(-2);
  }

  static timestampedUrl(url) {
    return url + `?timestamp=${new Date().getTime()}`;
  }

}