import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'


Vue.use(VueRouter)
const routes = [
  { 
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      title: 'Shio-Render',
      desc: '16日後までの予報(NOAAのGFS)を活用して登山などアウトドアの計画を立てよう。局所的な現象を予測する精度はありませんが、上空の寒気などから大局的な天気傾向をつかむことができる。ひまわりカラー画像や雨雲レーダーも。',
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  routes: routes,
})

router.beforeEach((to, from, next) => {
  const title_definition =
        to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  if(title_definition) {
    document.title = title_definition.meta.title;
    let desc = title_definition.meta.desc;
    if (!desc)  desc = '';
    document
      .querySelector("meta[name='description']")
      .setAttribute('content', desc);
  }

  next();
});

export default router
