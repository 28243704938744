import  { GeotiffLayer } from './GeotiffLayer';

export class EPTLayer extends GeotiffLayer {
  createThresholdColor() {
    const interval = 3; // 3K
    const min_K = this.data_obj.raster[0].reduce((a, b) => a < b ? a : b);
    const max_K = this.data_obj.raster[0].reduce((a, b) => b < a ? a : b);
    const min_value = Math.floor(min_K / interval) * interval;
    const max_value = Math.ceil (max_K / interval) * interval;
    const num = parseInt((max_value - min_value) / interval) + 1;
    const thresholds = [...Array(num)].map((v, i) => min_value + i*interval); // ascending
    let colors = {};
    for (let k of thresholds) {
      colors[k] = k >= 351 ? '#ff0000':
                  k >= 345 ? '#ff2000':
                  k >= 339 ? '#ff4000': 'transparent';
                  //k >= 333 ? '#ff6000':
                  //k >= 327 ? '#ff8000':
                  //k >= 321 ? '#ff9f00':
                  //k >= 315 ? '#ffbf00': 'transparent';
    }
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor.colors, 0.5, 
      ( value => value % 15 == 0 ? 1.5 : 0.8),
      ( value => value % 6 === 0 ? value : '' ));
    return [color_layer, line_layer];
  }
}