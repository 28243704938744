import  { GeotiffLayer } from './GeotiffLayer';

export class TempLayer extends GeotiffLayer {
  C0 = 273.15;
  STANDARD_TEMP = { cold: undefined, warn: undefined };

  lookupStandardTemp() {
    const mmdd = this.basetime.slice(4, 8);
    if (this.surface == 500) {
      this.STANDARD_TEMP.cold = // 寒気の目安
         101 <= mmdd && mmdd <=  320 ? -30 :            // 冬季   雪
         321 <= mmdd && mmdd <=  531 ? -21 :            // 春季   山の雪、大雨
         601 <= mmdd && mmdd <=  714 ?  -9 :            // 梅雨季 大雨
         715 <= mmdd && mmdd <=  920 ?  -6 :            // 盛夏   雷
         921 <= mmdd && mmdd <= 1009 ? -15 :            // 初秋   大雨
        1010 <= mmdd && mmdd <= 1120 ? -21 :            // 秋季   大雨
        1121 <= mmdd && mmdd <= 1231 ? -30 : undefined; // 冬季   雪
    }
    else if (this.surface == 850) {
      this.STANDARD_TEMP.cold = // 寒気の目安
          101 <= mmdd && mmdd <=  531 ?  0 :             // ～GW明け 山の雪の目安
          601 <= mmdd && mmdd <=  714 ?  6 :             // 梅雨季
          715 <= mmdd && mmdd <=  920 ?  9 :             // 盛夏
          921 <= mmdd && mmdd <= 1231 ?  0 : undefined   // 秋分～ 山の雪の目安
      
      this.STANDARD_TEMP.warm = // 暖気の目安
         101 <= mmdd && mmdd <=  320 ?  6 :             // 冬季
         321 <= mmdd && mmdd <=  531 ? 12 :             // 春季
         601 <= mmdd && mmdd <=  714 ? 21 :             // 梅雨季
         715 <= mmdd && mmdd <=  920 ? 24 :             // 盛夏
         921 <= mmdd && mmdd <= 1019 ? 18 :             // 初秋
        1020 <= mmdd && mmdd <= 1120 ?  9 :             // 秋季
        1121 <= mmdd && mmdd <= 1231 ?  6 : undefined   // 冬季
    }
    else {  // 地上
      this.STANDARD_TEMP.cold = // 寒気の目安
          101 <= mmdd && mmdd <=  531 ?   3 :             // ～GW明け 山の雪の目安
          601 <= mmdd && mmdd <=  714 ?   9 :             // 梅雨季
          715 <= mmdd && mmdd <=  920 ?  12 :             // 盛夏
          921 <= mmdd && mmdd <= 1231 ?   3 : undefined   // 秋分～ 山の雪の目安
      
      this.STANDARD_TEMP.warm = // 暖気の目安
         101 <= mmdd && mmdd <=  320 ? 12 :               // 冬季
         321 <= mmdd && mmdd <=  531 ? 18 :               // 春季
         601 <= mmdd && mmdd <=  714 ? 27 :               // 梅雨季
         715 <= mmdd && mmdd <=  920 ? 30 :               // 盛夏
         921 <= mmdd && mmdd <= 1019 ? 24 :               // 初秋
        1020 <= mmdd && mmdd <= 1120 ? 15 :               // 秋季
        1121 <= mmdd && mmdd <= 1231 ? 12 :  undefined;   // 冬季
    }
  }

  getColdColor(diff) {
    diff = Math.round(diff);
    return  diff <= -15 ? '#1464d3' :
            diff <= -12 ? '#1e6eeb' :
            diff <=  -9 ? '#2883f1' :
            diff <=  -6 ? '#3c97f5' :
            diff <=  -3 ? '#50a5f5' :
            diff <=   0 ? '#78b9fb' : 'transparent';
  }

  getWarmColor(diff) {
    diff = Math.round(diff);
    return  diff >=  6  ? '#f82019':
            diff >=  3  ? '#f57009':
            diff >=  0  ? '#f8a806': 'transparent';
  }

  createThresholdColor() {
    let thresholdColor = { 
      cold: { thresholds: [], colors: {} }, 
      warm: { thresholds: [], colors: {} }
    };
    if (this.data_obj.raster[0].length === 0)
      return undefined;
    const interval = 3;
    const min_K = this.data_obj.raster[0].filter(x => x < 9999).reduce((a, b) => a < b ? a : b);
    const max_K = this.data_obj.raster[0].filter(x => x < 9999).reduce((a, b) => a < b ? b : a);
    const min_C = Math.floor((min_K - this.C0) / interval) * interval;
    const max_C = Math.ceil((max_K - this.C0) / interval) * interval;
    const num = parseInt((max_C - min_C) / interval) + 1;
    this.lookupStandardTemp();
    if (this.STANDARD_TEMP.cold !== undefined) {
      thresholdColor.cold.thresholds = [...Array(num)].map((v, i) => this.Round(min_C + this.C0 + (num-i-1)*interval, 2)); // round off to 2 decimal places, descending
      thresholdColor.cold.thresholds.forEach(temp => {
        thresholdColor.cold.colors[temp] = this.getColdColor(temp-this.C0 - this.STANDARD_TEMP.cold);
      });
    }
    if (this.STANDARD_TEMP.warm !== undefined) {
      thresholdColor.warm.thresholds = [...Array(num)].map((v, i) => this.Round(min_C + this.C0 + i*interval, 2)); // round off to 2 decimal places, ascending
      thresholdColor.warm.thresholds.forEach(temp => {
        thresholdColor.warm.colors[temp] = this.getWarmColor(temp-this.C0 - this.STANDARD_TEMP.warm);
      })
    }
    return thresholdColor;
  }
  
  createLayers() {
    //this.lookupStandardTemp();
    // Do no fill color outside 0 <= longitude <= 360.
    //const no_fill_value = this.C0 + (this.STANDARD_TEMP.cold + this.STANDARD_TEMP.warm)/2;
    //this.f_scalingPixelValues = x => x === 0 ? no_fill_value : x;
    let color_layers = [];
    const thresholdColor = this.createThresholdColor();
    if (!thresholdColor)
      return color_layers;
    let polygon = undefined;
    for (let type in thresholdColor) {  // type: cold or warm
      if (thresholdColor[type].thresholds.length === 0) continue;
      polygon = this.createPolygon(thresholdColor[type].thresholds);
      color_layers.push(this.createColorLayer(polygon, thresholdColor[type].colors));
    }
    let line_colors = {};
    Object.assign(line_colors, thresholdColor.cold.colors);
    for (let temp in line_colors) {
      if (line_colors[temp] === 'transparent')
        line_colors[temp] = thresholdColor.warm.colors[temp];
      if (line_colors[temp] === 'transparent')
        line_colors[temp] = '#a0a0a0';
    }
    // create lines-layer by using either cold or warm polygon.
    const line_layer = this.createLineLayer(polygon, 
      line_colors, 1.0, 1.0,
      ( value => Math.round(value - this.C0) ));
    return color_layers.concat([line_layer]); 
  }
}