import GeoTIFF, {fromArrayBuffer} from 'geotiff';       // MIT
import { Util } from '../util/util';

export class CogBase {
  tiffBase = undefined;
  wholeImage = undefined;

  constructor() {
    GeoTIFF;
  }

  async fetch(url) {
    const response = await fetch(Util.timestampedUrl(url));
    const content_type = response.headers.get('Content-Type');
    console.log(`Content-Type = ${content_type}`);
    if (!content_type.includes('image/tiff')) {
      return false;
    }
    const arrayBuffer = await response.arrayBuffer();
    this.tiffBase = await fromArrayBuffer(arrayBuffer);
    this.wholeImage = await this.tiffBase.getImage();
    return true;
  }
    
  calcPortionResolution(portion) {
    const wholeOrigin = this.wholeImage.getBoundingBox();
    return [
      (wholeOrigin[2] - wholeOrigin[0]) / portion.getWidth(),
      (wholeOrigin[3] - wholeOrigin[1]) / portion.getHeight()
    ];
  }

  getWholeOrigin() {
    return this.wholeImage.getOrigin();
  }
    
  async findPortionByResolution(res) {   // get the image with the worst acceptable resolution.
    let usedImage = this.wholeImage;
    const imageCount = await this.tiffBase.getImageCount();
    const allImages = [];
    for (let i = 0; i < imageCount; ++i) {
      const image = await this.tiffBase.getImage(i);
      const { SubfileType: subfileType, NewSubfileType: newSubfileType } = image.fileDirectory;
      if (i === 0 || subfileType === 2 || newSubfileType & 1) {
        allImages.push(image);
      }
    }
    allImages.sort((a, b) => a.getWidth() - b.getWidth());
    for (let i = 0; i < allImages.length; ++i) {
      const portion = allImages[i];
      const portionRes = this.calcPortionResolution(portion);
      usedImage = portion;
      if ((res[0] && res[0] > portionRes[0]) || (res[1] && res[1] > portionRes[1])) {
        break;
      }
    }
    return usedImage;
  }
  
  geImagetWnd(image, bbox) {
    const wholeOrigin = this.wholeImage.getOrigin();
    const wholeRes = image.getResolution(this.wholeImage);
    /* ************************************************** 
      bbox[3] ------------         wnd[1] ------------
              |          |                |          |
              |          |                |          |
              |          |                |          |
      bbox[1] ------------         wnd[3] ------------
            bbox[0]    bbox[2]          wnd[0]     wnd[2]
    *************************************************** */
    let wnd = [
      Math.floor((bbox[0] - wholeOrigin[0]) / wholeRes[0]) - 1, // x of upper-left
      Math.floor((bbox[3] - wholeOrigin[1]) / wholeRes[1]) - 1, // y of upper-right
      Math.ceil((bbox[2] - wholeOrigin[0]) / wholeRes[0])  + 1, // x of bottom-right
      Math.ceil((bbox[1] - wholeOrigin[1]) / wholeRes[1])  + 1, // y of bottom-left
    ];
    wnd = [
      Math.max(0, Math.min(image.getWidth(), wnd[0])),
      Math.max(0, Math.min(image.getWidth(), wnd[1])),
      Math.max(0, Math.min(image.getWidth(), wnd[2])),
      Math.max(0, Math.min(image.getHeight(), wnd[3])),
    ];
    return wnd;   
  }

  async getPortionRasters(portion, wnd, resampleMethod='bilinear') {
    return await portion.readRasters({
      window: wnd,
      resampleMethod: resampleMethod, // nearest(default) or bilinear
    });
  }
}
