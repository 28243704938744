import L from 'leaflet'
import  { GeotiffLayer } from './GeotiffLayer';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export class CDCLayer extends GeotiffLayer {

  createLayers() {
    let thresholds = [20, 40, 60, 80, 99]; //[...Array(4)].map((_, i) => (i+1)*20);
    const colors = {};
    thresholds.forEach(x => {
      if      (this.element === 'LCDC') colors[x] = '#999999D0';
      else if (this.element === 'MCDC') colors[x] = '#CCCCCCC0';
      else if (this.element === 'HCDC') colors[x] = '#FFFFFFA0';
      else                              colors[x] = '#FFFFFFC0';
    });
    const polygon = this.createPolygon(thresholds);
    const color_layer = this.createColorLayer(polygon, colors);
    return [color_layer];
  }

  lookupDotPatten(val) {
    if      (90 <= val)  return [ 1,1,1,1,1,1,1,1,1,1 ];
    else if (80 <= val)  return [ 1,1,1,1,0,1,1,1,1,0 ];
    else if (60 <= val)  return [ 1,1,0,1,0,1,1,0,1,0 ];
    else if (40 <= val)  return [ 1,0,1,0,0,1,0,1,0,0 ];
    else if (20 <= val)  return [ 1,0,0,0,0,1,0,0,0,0 ];
    else                 return [ 0,0,0,0,0,0,0,0,0,0 ];
  }

  lookupPatternAngle() {
    if      (this.element === 'LCDC') return 0.0;
    else if (this.element === 'MCDC') return 45.0;
    else if (this.element === 'HCDC') return 90.0;
    return 0;
  }

  createColorLayer(polygon, colors={}) {
    if (!polygon) return;
    return L.geoJson(polygon, {
        renderer: this.colorRenderer,
        style: function(feature) {
          let color = colors[feature.properties[0].value];
          let opacity = 1;
          const rgba_pattern = /^(#[0-9a-f]{6})([0-9a-f]{2})$/ig;
          const is_rgba = rgba_pattern.exec(color);
          if (is_rgba) {
            color = is_rgba[1];
            opacity = parseInt(is_rgba[2], 16) / 255;
          }
          if (!color || color === 'transparent') {
            color = '#000000';
            opacity = 0;
          }
          //const dot_pattern_size = 5
          //const dot_size = Math.ceil(feature.properties[0].value / 20);
          //const dot_space = dot_pattern_size - dot_size;
          const pattern_id = `${this.element}-${feature.properties[0].value}`;
          return {
            weight: 0,
            opacity: 0,
            fill: true,
            fillOpacity: 1.0,
            patternId: pattern_id,
            patternArray: this.lookupDotPatten(feature.properties[0].value),
            patternAngle: this.lookupPatternAngle(),
            patternScale: 0.7,
            //dotSize: dot_size,
            //dotSpace: dot_space,
            patternColor: color,
            patternOpacity: opacity,
          }
        }.bind(this)
      });
  }
}
