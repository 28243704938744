<template>
  <AppMain />
</template>

<script>
  import AppMain from '../components/AppMain'

  export default {
    name: 'Home',

    components: {
      AppMain,
    },
  }
</script>
