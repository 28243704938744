L.TileLayer.NonFlickering = L.TileLayer.extend({

  newTilesUrlLoadStatus: {},

  _updateOneTile: function(key) {
    this.newTilesUrlLoadStatus[key].tile.el.src = this.newTilesUrlLoadStatus[key].url;
  },

  _refreshTileUrl: function(key, updated_by_each_tile) {
    //use a image in background, so that only replace the actual tile, once image is loaded in cache!
    var img = new Image();

    img.onload = function() {
      if (updated_by_each_tile) {
        this._updateOneTile(key);
        return;
      }
      this.newTilesUrlLoadStatus[key].loaded = true;
      var keys = Object.keys(this.newTilesUrlLoadStatus);
      var unloaded_keys = keys.filter((key) => {
        return !this.newTilesUrlLoadStatus[key].loaded;
      });
      if (!unloaded_keys.length) {
        L.Util.requestAnimFrame(() => {
          keys.forEach((key) => this._updateOneTile(key));
        });
      }
    }.bind(this);

    img.src = this.newTilesUrlLoadStatus[key].url;
  },

  refresh: function(updated_by_each_tile=false) {
    //prevent _tileOnLoad/_tileReady re-triggering a opacity animation
    var wasAnimated = this._map._fadeAnimated;
    this._map._fadeAnimated = false;

    this.newTilesUrlLoadStatus = {};
    for (var key in this._tiles) {
      const tile = this._tiles[key];
      if (tile.current && tile.active) {
        var oldsrc = tile.el.src;
        var newsrc = this.getTileUrl(tile.coords);
        if (oldsrc != newsrc) {
          this.newTilesUrlLoadStatus[key] = { tile: tile, url: newsrc, loaded: false };
        }
      }
    }
    Object.keys(this.newTilesUrlLoadStatus).forEach((key) => {
      this._refreshTileUrl(key, updated_by_each_tile);
    })

    if (wasAnimated)
      setTimeout(function() { this._map._fadeAnimated = wasAnimated; }, 5000);
  },

  updateUrl: function(url) {
		// 'true' is to disable the internal tilelayer.redraw()
    this.setUrl(url, true);
  },

});

L.tileLayer.nonflickering = function(url, options) {
  return new L.TileLayer.NonFlickering(url, options);
}