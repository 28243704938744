import L from 'leaflet';
import * as topojson from "topojson-client";

export class TopoJSON extends L.GeoJSON {
  addData(data) {
    if (data.type === "Topology") {
      for (let key in data.objects) {
        if (Object.prototype.hasOwnProperty.call(data.objects, key)) {
          const geojson = topojson.feature(data, data.objects[key]);
          L.GeoJSON.prototype.addData.call(this, geojson);
        }
      }
      return this;
    }
    L.GeoJSON.prototype.addData.call(this, data);
    return this;
  }
}