import  { GeotiffLayer } from './GeotiffLayer';

export class KINXLayer extends GeotiffLayer {
  createThresholdColor() {
    const colors = {
        40 : '#c7408e',
        35 : '#ff5e40',
        30 : '#ffb340',
        25 : '#fff840',
        20 : '#4071ff',
        15 : '#59a9ff',
    };
    const thresholds = Object.keys(colors).sort((a, b) => parseInt(a) - parseInt(b)); // ascending
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor, 0.5, 0.5);
    return [color_layer, line_layer];
  }
}