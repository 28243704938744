import  { GeotiffLayer } from './GeotiffLayer';

export class PressLayer extends GeotiffLayer {
  createThresholdColor() {
    const interval = 400; // isolines every 400Pa=4hPa
    const min_P = this.data_obj.raster[0].reduce((a, b) => a < b ? a : b);
    const max_P = this.data_obj.raster[0].reduce((a, b) => b < a ? a : b);
    const min_value = Math.floor(min_P / interval) * interval;
    const max_value = Math.ceil (max_P / interval) * interval;
    const num = parseInt((max_value - min_value) / interval) + 1;
    const thresholds = [...Array(num)].map((v, i) => min_value + i*interval);
    const colors = { };
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    //const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor.colors, 
      1, (value => { return Math.round(value/100) % 20 == 0 ? 2 : 1; }),
      (value => Math.round(value / 100)));
    return [/*color_layer,*/ line_layer];
  }
}