import L from 'leaflet'
import * as PIXI from 'pixi.js'
import 'leaflet-pixi-overlay'
import * as d3 from 'd3-quadtree'

export class PixiMarkersLayer {

  constructor(options) {

    L.PixiOverlay.include({  // Add a method of L.PixiOverlay.
      updateDrawCallback: function(drawCallback) {
        this._drawCallback = drawCallback;
        this._update({type: 'add'});
      },
    });
    
    this.pane = options.pane;  // if undefined pixiOverlay is created on 'overlayPane' with zIndex=500.
    this.mouseOverStyle = options.mouseOverStyle ? options.mouseOverStyle : 'leaflet-interactive';
    this.markerTint = options.markerTint ? options.markerTint : 0xFFFFFF;
    this.selectedMarkerTint = options.selectedMarkerTint ? options.selectedMarkerTint : 0xFFFFFF;
    this.zoomDuration = options.zoomDuration ? options.zoomDuration : 30;
    this.markerWidth = options.markerWidth; // size when zoomLevel = 9 (scaleFactor = 1). if undefined this is texture width.
    this.nonScalingZoomLevel = options.nonScalingZoomLevel ? 
          options.nonScalingZoomLevel : 9;  // marker is not scaled when zoom-level <= nonScalingZoomLevel
    this.selectedMarkerScaling = options.selectedMarkerScaling ? options.selectedMarkerScaling : 1.0;
    this.maxZindex = 500;

    const doubleBuffering = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
    this.pixiOptions = {
      doubleBuffering: doubleBuffering,
      autoPreventDefault: false
    };
    if (this.pane)
      this.pixiOptions.pane = this.pane;

    this._LayerPixiOverlay = undefined;
    this.pixiLoader = new PIXI.Loader();
    this.pixiContainer = new PIXI.Container();
    this.pixiContainer.sortableChildren = true;
    this.onClick = undefined;
    this.onMouseMove = undefined;
    this.markers = [];
  }

  _findMarker(layerPoint, quadTree) {
    let candidates = [];
    quadTree.visit(function(node, x1, y1, x2, y2) {
      let serch_r = 0;
      if (!node.length) {
        const dx = node.data.x - layerPoint.x;
        const dy = node.data.y - layerPoint.y;
        const distance = dx*dx + dy*dy;
        const r = node.data.width / 2;
        serch_r = Math.max(serch_r, r);
        do {
          if (distance <= r*r)
            candidates.push({marker: node.data, distance: distance});
        } while ((node = node.next));
      }
      return (
        layerPoint.x + serch_r < x1 ||
        layerPoint.y + serch_r < y1 ||
        x2 < layerPoint.x - serch_r ||
        y2 < layerPoint.y - serch_r
      );
    });
    if (candidates.length)
      return candidates.reduce((a,b) => a.distance < b.distance ? a : b).marker;
    return null;
  }

  _makeQuadTree(circles) {
    var tree = d3.quadtree()
                  .x(d => d.xp)
                  .y(d => d.yp);
    circles.forEach(circle => {
      circle.xp = circle.x;
      circle.yp = circle.y;
      circle.r = circle.width / 2;
      circle.xMin = circle.x - circle.r;
      circle.xMax = circle.x + circle.r;
      circle.yMin = circle.y - circle.r;
      circle.yMax = circle.y + circle.r;
      tree.add(circle);
    });
    return tree;     
  }

  _createSprite(marker, coords) {
    const texture = this.pixiLoader.resources[marker.resourceId].texture;
    const sprite = new PIXI.Sprite(texture);
    sprite.x = coords.x;
    sprite.y = coords.y;
    sprite.anchor.set(0.5, 0.5);
    sprite.tint = this.markerTint;
    // original properties
    sprite.legend = marker.name;
    sprite.resourceId = marker.resourceId;  // 100, 200, 300
    sprite.id = marker.id;
    sprite.zIndex = this.maxZindex - sprite.id;
    //sprite.interactive = true;
    //sprite.buttonMode = true;
    //sprite.on('click', () => {
    //  console.log(sprite.legend);
    //});
    return sprite;
  }

  _revertNormalState(sprite) {
    sprite.texture = this.pixiLoader.resources[sprite.resourceId].texture;
    sprite.tint = this.markerTint;
    sprite.scale.set(sprite.scale.x / this.selectedMarkerScaling);
    sprite.zIndex = this.maxZindex - sprite.id;
  }

  _updateSelectedState(sprite) {
    sprite.texture = this.pixiLoader.resources[`selected${sprite.resourceId}`].texture;
    sprite.tint = this.selectedMarkerTint;
    sprite.scale.set(sprite.scale.x * this.selectedMarkerScaling);
    sprite.zIndex = this.maxZindex;
  }

  _showPopup(map, popup, latlng, content) {
    popup.setLatLng(latlng);
    popup.setContent(content);
    popup.openOn(map);
  }

  _removeMouseEventHandlers() {
    const map = this._LayerPixiOverlay.utils.getMap();
    map.off('click', this.onClick);
    map.off('mousemove', this.onMouseMove);
  }

  createDrawCallbackFunc(markers_to_be_added, sprites_to_be_removed=[]) {
    let firstDraw = true;
    let prevZoom = undefined;
    let frame = undefined;
    let selected = undefined;
    const popup = L.popup();

    return function(utils) {
      const map = utils.getMap();
      let zoom = map.getZoom();

      if (frame) {
        cancelAnimationFrame(frame);
        frame = null;
      }
      const renderer = utils.getRenderer();
      const scaleFactor = utils.getScale();
      let markerScalingRatio = 1 / scaleFactor;
      if (zoom <= this.nonScalingZoomLevel) {
        markerScalingRatio = 1 / utils.getScale(this.nonScalingZoomLevel);
      }
      if (firstDraw) {
        prevZoom = zoom;
        markers_to_be_added.forEach((marker) => {
          const sprite = this._createSprite(marker,
                            utils.latLngToLayerPoint([marker.latitude, marker.longitude]));
          this.pixiContainer.addChild(sprite);
        });
        sprites_to_be_removed.forEach((sprite) => {
          this.pixiContainer.removeChild(sprite);
          sprite.destroy({children:true, texture:false, baseTexture:true});
          sprite = null;
        });
        const quadTree = this._makeQuadTree(this.pixiContainer.children);

        this.onClick = (e) => {
          let redraw = false;
          if (selected) {
            this._revertNormalState(selected);
            selected = null;
            redraw = true;
          }
          const coords = utils.latLngToLayerPoint(e.latlng);
          const found = this._findMarker(coords, quadTree, utils.getScale());
          if (found) {
            this._updateSelectedState(found);
            this._showPopup(map, popup, e.latlng, found.legend);
            selected = found;
            redraw = true;
          }
          if (redraw)
            renderer.render(this.pixiContainer);
        };

        this.onMouseMove = (e) => {
          const move = (e) => {
            let layerPoint = utils.latLngToLayerPoint(e.latlng);
            const found = this._findMarker(layerPoint, quadTree, utils.getScale());
            if (found) {
              L.DomUtil.addClass(map._container, this.mouseOverStyle);
            } else {
              L.DomUtil.removeClass(map._container, this.mouseOverStyle);
            }
          } 
          L.Util.throttle(move(e), 32);
        };

        map.on('click', this.onClick);
        map.on('mousemove', this.onMouseMove);
      }

      if (firstDraw || prevZoom !== zoom) {
        this.pixiContainer.children.forEach((sprite) => {
          let sprite_scale = markerScalingRatio;
          if (this.markerWidth)
            sprite_scale *= this.markerWidth / sprite.texture.width;
          if (sprite === selected)
            sprite_scale *= this.selectedMarkerScaling;
          if (firstDraw) {
              sprite.scale.set(sprite_scale);
          } else {
            sprite.currentScale = sprite.scale.x;
            sprite.targetScale = sprite_scale;
          }
        });
      }

      let zomm_start_timestamp = null;
      const cb_animate = (timestamp) => {
        if (zomm_start_timestamp === null) zomm_start_timestamp = timestamp;
        let elapsed = timestamp - zomm_start_timestamp;
        let progress = elapsed / this.zoomDuration;
        if (progress > 1) progress = 1;
        const coefficient = progress * (0.4 + progress * (2.2 + progress * -1.6));
        this.pixiContainer.children.forEach(function(sprite) {
          const scale = sprite.currentScale + 
                        coefficient * (sprite.targetScale - sprite.currentScale)
          sprite.scale.set(scale);
        });
        renderer.render(this.pixiContainer);
        if (elapsed < this.zoomDuration) {
          frame = requestAnimationFrame(cb_animate);
        }
      }

      if (!firstDraw && prevZoom !== zoom) {
        frame = requestAnimationFrame(cb_animate);
      }
      firstDraw = false;
      prevZoom = zoom;
      renderer.render(this.pixiContainer);
    }.bind(this);
  }

  getJSON(url, successHandler, errorHandler=undefined) {
    var xhr = new XMLHttpRequest();
    xhr.open('get', url, true);
    xhr.onreadystatechange = function() {
      var status;
      var data;
      if (xhr.readyState == 4) {
        status = xhr.status;
        if (status == 200) {
          data = JSON.parse(xhr.responseText);
          successHandler && successHandler(data);
        } else {
          errorHandler && errorHandler(status);
        }
      }
    };
    xhr.send();
  }

  load(loader, resources) {
    this.pixiLoader.load(loader, resources);
  }

  addResource(resource_name, texture_path) {
    resource_name = String(resource_name);
    if (!this.pixiLoader)
      this.pixiLoader = new PIXI.Loader();
    if (!this.pixiLoader.resources[resource_name])
      this.pixiLoader.add(resource_name, texture_path);
    return this;
  }

  addSelectedResource(resource_name, texture_path) {
    const selected_res_name = `selected${resource_name}`;
    return this.addResource(selected_res_name, texture_path);
  }

  createLayer(markers) {
    const cb = this.createDrawCallbackFunc(markers)
    this._LayerPixiOverlay = L.pixiOverlay(cb, this.pixiContainer, this.pixiOptions);
  }

  addMarkers(markers) {
    this._removeMouseEventHandlers();
    const cb = this.createDrawCallbackFunc(markers);
    this._LayerPixiOverlay.updateDrawCallback(cb);
  }

  removeMarkers(resource_id) {
    this._removeMouseEventHandlers();
    const sprites_to_be_removed = this.pixiContainer.children.filter(sprite => sprite.resourceId === resource_id);
    const cb = this.createDrawCallbackFunc([], sprites_to_be_removed);
    this._LayerPixiOverlay.updateDrawCallback(cb);
  }

  replaceMarkers(markers) {
    this._removeMouseEventHandlers();
    const sprite_ids_in_container = this.pixiContainer.children.map(s => s.id);
    const marker_ids = markers.map(m => m.id);
    const markers_to_be_added = markers.filter(m => !sprite_ids_in_container.includes(m.id));
    const sprites_to_be_removed = this.pixiContainer.children.filter(sprite => !marker_ids.includes(sprite.id));
    const cb = this.createDrawCallbackFunc(markers_to_be_added, sprites_to_be_removed)
    this._LayerPixiOverlay.updateDrawCallback(cb);
  }

  removeAllSprites() {
    this._removeMouseEventHandlers();
    while (this.pixiContainer.children[0]) {
      this.pixiContainer.removeChild(this.pixiContainer.children[0]);
      if (this.pixiContainer.children[0]) {
        this.pixiContainer.children[0].destroy({children:true, texture:false, baseTexture:true});
        this.pixiContainer.children[0] = null;
        this.pixiContainer.children.shift();
      }
    }
  }

  hasPixiOverlay() {
    return (this._LayerPixiOverlay) ? true : false;
  }

  addToMap(map) {
    this._LayerPixiOverlay.addTo(map);
  }

  removeFromMap(map) {
    map.removeLayer(this._LayerPixiOverlay);
  }
}
