export default {
  createMenuTree : function() {
    return [
      {
        id: 'LFM', name: '局地予報(LFM)',
        children: [
          {
            id: 'LFM/SURFACE', name: '地上',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'LFM/LCDC', name: '下層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud1_pane',
                zIndex: 320,  // tilePane(200) < colorLayers < overlayPane(400)
                opacity: 1.0,
              },
              {
                id: 'LFM/MCDC', name: '中層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud2_pane2',
                zIndex: 321,
                opacity: 1.0,
              },
              {
                id: 'LFM/HCDC', name: '上層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud3_pane',
                zIndex: 322,
                opacity: 1.0,
              },
              {
                id: 'LFM/P1HP', name: '降水量',
                select_type: 'checkbox',
                selected: false,
                pane: 'prate_pane',
                zIndex: 330,
                opacity: 0.7,
              },
              {
                id: 'LFM/TMP', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 0.5,
              },
              {
                id: 'LFM/RH', name: '相対湿度',
                select_type: 'checkbox',
                selected: false,
                pane: 'rh_pane',
                zIndex: 340,
                opacity: 0.5,
              },
              {
                id: 'LFM/WIND', name: '地上風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/SKYWIND', name: '上空風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/WIND_1000', name: '風1000',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/WIND_975', name: '風975',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/WIND_950', name: '風950',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/Z_SKYWIND', name: '上空高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/HGT_975', name: '高度975',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'LFM/HGT_950', name: '高度950',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 350,
                opacity: 0.97,
              },
            ],
          }
        ],
      },
      {
        id: 'MSM', name: 'メソ予報(MSM)',
        children: [
          {
            id: 'MSM/SURFACE', name: '地上',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'MSM/LCDC', name: '下層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud1_pane',
                zIndex: 320,  // tilePane(200) < colorLayers < overlayPane(400)
                opacity: 1.0,
              },
              {
                id: 'MSM/MCDC', name: '中層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud2_pane2',
                zIndex: 321,
                opacity: 1.0,
              },
              {
                id: 'MSM/HCDC', name: '上層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud3_pane',
                zIndex: 322,
                opacity: 1.0,
              },
              {
                id: 'MSM/P1HP', name: '降水量',
                select_type: 'checkbox',
                selected: false,
                pane: 'prate_pane',
                zIndex: 330,
                opacity: 0.7,
              },
              {
                id: 'MSM/TMP', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 0.5,
              },
              {
                id: 'MSM/RH', name: '相対湿度',
                select_type: 'checkbox',
                selected: false,
                pane: 'rh_pane',
                zIndex: 340,
                opacity: 0.5,
              },
              {
                id: 'MSM/WIND', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
            ],
          }
        ],
      },
      {
        id: 'GFS', name: '予報(GFS)',
        children: [
          {
            id: 'GFS/SURFACE', name: '地上',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/LCDC', name: '下層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud1_pane',
                zIndex: 320,  // tilePane(200) < colorLayers < overlayPane(400)
                opacity: 1.0,
              },
              {
                id: 'GFS/MCDC', name: '中層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud2_pane2',
                zIndex: 321,
                opacity: 1.0,
              },
              {
                id: 'GFS/HCDC', name: '上層雲',
                select_type: 'checkbox',
                selected: false,
                pane: 'cloud3_pane',
                zIndex: 322,
                opacity: 1.0,
              },
              {
                id: 'GFS/PRATE', name: '降水量(PRATE)',
                select_type: 'checkbox',
                selected: false,
                pane: 'prate_pane',
                zIndex: 330,
                opacity: 0.7,
              },
              {
                id: 'GFS/P1HP', name: '降水量(APCP)',
                select_type: 'checkbox',
                selected: false,
                pane: 'apcp_pane',
                zIndex: 331,
                opacity: 0.7,
              },
              {
                id: 'GFS/TMP', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 0.5,
              },
              {
                id: 'GFS/WIND', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/PRMSL', name: '気圧',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ],
          },
          {
            id: 'GFS/950', name: '950 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/EPT_950', name: '相当温位',
                select_type: 'checkbox',
                selected: false,
                pane: 'ept_pane',
                zIndex: 310,
                opacity: 0.5,
              },
              {
                id: 'GFS/WIND_950', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
            ]
          },
          {
            id: 'GFS/850', name: '850 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/TMP_850', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 0.5,
              },
              {
                id: 'GFS/EPT_850', name: '相当温位',
                select_type: 'checkbox',
                selected: false,
                pane: 'ept_pane',
                zIndex: 310,
                opacity: 0.5,
              },
              {
                id: 'GFS/WIND_850', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/HGT_850', name: '高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ]
          },
          {
            id: 'GFS/700', name: '700 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/TMP_700', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 1.0,
              },
              {
                id: 'GFS/WIND_700', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
            ]
          },
          {
            id: 'GFS/500', name: '500 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/TMP_500', name: '気温',
                select_type: 'checkbox',
                selected: false,
                pane: 'tmp_pane',
                zIndex: 300,
                opacity: 1.0,
              },
              {
                id: 'GFS/WIND_500', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/HGT_500', name: '高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ]
          },
          {
            id: 'GFS/300', name: '300 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/WIND_300', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/HGT_300', name: '高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ]
          },
          {
            id: 'GFS/250', name: '250 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/WIND_250', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/HGT_250', name: '高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ]
          },
          {
            id: 'GFS/200', name: '200 hPa',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/WIND_200', name: '風',
                select_type: 'checkbox',
                selected: false,
                pane: 'wind_pane',
                zIndex: 350,
                opacity: 0.97,
              },
              {
                id: 'GFS/HGT_200', name: '高度',
                select_type: 'checkbox',
                selected: false,
                pane: 'prmsl_pane',
                zIndex: 320,
                opacity: 0.5,
              },
            ]
          },
          {
            id: 'GFS/INDEX', name: '安定指数',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            children: [
              {
                id: 'GFS/SSI', name: 'SSI',
                select_type: 'checkbox',
                selected: false,
                pane: 'ssi_pane',
                zIndex: 310,
                opacity: 0.5,
              },
              {
                id: 'GFS/KINX', name: 'K指数',
                select_type: 'checkbox',
                selected: false,
                pane: 'kinx_pane',
                zIndex: 311,
                opacity: 0.5,
              },
            ]
          },
        ],
      },
      {
        id: 'Obs', name: 'ひまわり／レーダー',
        children: [
          {
            id: 'NOWC', name: 'レーダー',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'nowc_pane',
            zIndex: 260,
            opacity: 0.8,
          },
          {
            id: 'HIMAWARI/B13', name: 'ひまわり赤外',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'b13_pane',
            zIndex: 250,
            opacity: 1.0,
          },
          {
            id: 'HIMAWARI/B03', name: 'ひまわり可視',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'b03_pane',
            zIndex: 250,
            opacity: 1.0,
          },
          {
            id: 'HIMAWARI/B08', name: 'ひまわり水蒸気',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'b08_pane',
            zIndex: 250,
            opacity: 1.0,
          },
          {
            id: 'HIMAWARI/REP', name: 'ひまわりカラー',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'rep_pane',
            zIndex: 250,
            opacity: 1.0,
          },
          {
            id: 'HIMAWARI/SND', name: 'ひまわり雲頂強調',
            select_type: 'radio',
            radio_group: 'surface',
            selected: false,
            pane: 'snd_pane',
            zIndex: 250,
            opacity: 0.6,
          },
        ],
      },
    ]
  }
};
