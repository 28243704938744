import  { GeotiffLayer } from './GeotiffLayer';

export class RHLayer extends GeotiffLayer {
  createThresholdColor() {
    const colors = {
        90 : '#2f51a3',
        80 : '#4563ae',
        70 : '#6a86c3',
        60 : '#8baedb',
        50 : '#afe1f3',
        40 : '#c6e6de',
        30 : '#c6d4b5',
        20 : '#c5b38e',
        10 : '#c4a868',
         0 : '#c1953d',
    };
    const thresholds = Object.keys(colors).sort((a, b) => parseInt(a) - parseInt(b)); // ascending
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor, 0.5, 0.5);
    return [color_layer, line_layer];
  }
}