import L from 'leaflet'
import { CogBase } from './CogBase';

/*
const LandUseClassRoughness = {
  10 : 0.8,    // Forest: Area dominated by trees higher than shrubs with a canopy cover greater than or equal to 10 percent.
  20 : 0.03,   // Mixture: Area where more than two classes are mixed including Non-vegetated area, Agricultural area, 
               //   Grassland/Shrub and Wetland. This class is not applied where one class dominates.
  30 : 0.2,    // Grassland/shrub: Area covered by trees with canopy cover less than l0 percent.
  40 : 0.1,    // Agricultural area: Area where agricultural activities are implemented constantly.
  50 : 0.001,  // Wetland: Area where underground water level is near the ground surface, or area with humid soil.
  60 : 0.003,  // Barren area: Non-vegetated area where no artificial structures exist.
  70 : 1.0,    // Built-up area: Area where artificial structures occupy significant surfaces.
  80 : 0.03,   // Drainage/water: Area inside coastline forming water surface.
  90 : 0.0002, // Ocean: Area outside coastline forming water surface.
}
*/

export class SkyWindyLayer {
  data_obj = undefined;
  element = undefined;
  pane_name = undefined;
  altitude = 10;
  Au = undefined;
  Av = undefined;
  Bu = undefined;
  Bv = undefined;


  constructor(element) {
    this.element = element;
    this.pane_name = "animationWindPane";
    this.model = undefined;
    this.basetime = 0;
    this.validtime = 0;
    this.cog = new CogBase();
    this.cogB = new CogBase();
  }

  /*
  calcSkyWindRatio(Z, baseZ, landUseValue) {
    const Zo = LandUseClassRoughness[landUseValue];
    return Math.log(Z/Zo) / Math.log(baseZ/Zo)
  }
  */

  async getGeotiffData(model, basetime, validtime, bounding_box, expected_resolution) {
    if (model     !== this.model || 
        basetime  !== this.basetime ||
        validtime !== this.validtime) {
      let url = `/tiff/${model}/${this.element}/${basetime}/${validtime}.tiff`;
      if (! await this.cog.fetch(url))
        return false;
    }
    const portion = await this.cog.findPortionByResolution(expected_resolution);
    const wnd = this.cog.geImagetWnd(portion, bounding_box);
    const portion_rasters = await this.cog.getPortionRasters(portion, wnd);
    const portionRes = this.cog.calcPortionResolution(portion);
    const wholeOrigin = this.cog.getWholeOrigin();
    this.Au = Array.from(portion_rasters[0]);
    this.Av = Array.from(portion_rasters[1]);
    this.Bu = Array.from(portion_rasters[2]);
    this.Bv = Array.from(portion_rasters[3]);
    console.log(this.Au.reduce((acc, cur) => acc + cur)/this.Au.length);
    console.log(this.Av.reduce((acc, cur) => acc + cur)/this.Av.length);

    const uv = this.calcSkyUV(this.altitude);
    console.log(uv)
    this.data_obj = [
      {
        'header': {
          'parameterCategory': 1,
          'parameterNumber': 2,
          'lo1': wnd[0] * portionRes[0] + wholeOrigin[0],    // longitude of upper-left
          'la1': -1.0 * wnd[1] * portionRes[1] + wholeOrigin[1],    // latitude of upper-left 
          'dx' : portionRes[0],
          'dy' : portionRes[1],
          'nx' : portion_rasters.width,
          'ny' : portion_rasters.height,
          //'refTime': "2017-02-01 23:00:00",
        },
        'data': uv.u,
      },
      {
        'header': {
          'parameterCategory': 1,
          'parameterNumber': 3,
          'lo1': wnd[0] * portionRes[0] + wholeOrigin[0],    // longitude of upper-left
          'la1': -1.0 * wnd[1] * portionRes[1] + wholeOrigin[1],    // latitude of upper-left 
          'dx' : portionRes[0],
          'dy' : portionRes[1],
          'nx' : portion_rasters.width,
          'ny' : portion_rasters.height,
          //'refTime': "2017-02-01 23:00:00",
        },
        'data': uv.v,
      }
    ];
    this.model = model;
    this.basetime = basetime;
    this.validtime = validtime;
    return true;
  }

  async setColorRenderer(renderer) {
    this.pane_name = renderer.options.pane;
  }
  setLineLabelsRenderer(renderer) {
    renderer;
  }

  getColorScale() {
    /*
    return [
      '#fffff0',
      '#0096ff',
      '#faf500',
      '#ff9900',
      '#ff2800',
      '#b40068',
    ];
    */
   return [
      "rgb(36,104, 180)",
      "rgb(60,157, 194)",
      "rgb(128,205,193 )",
      "rgb(151,218,168 )",
      "rgb(198,231,181)",
      "rgb(238,247,217)",
      "rgb(255,238,159)",
      "rgb(252,217,125)",
      "rgb(255,182,100)",
      "rgb(252,150,75)",
      "rgb(250,112,52)",
      "rgb(245,64,32)",
      "rgb(237,45,28)",
      "rgb(220,24,32)",
      "rgb(180,0,35)",
   ];
  }

  async createLayers() {
    var velocityLayer = await L.shioVelocityLayer({
        displayValues: true,
        displayOptions: {
          // label prefix
          velocityType: "",
          // leaflet control position
          position: "bottomleft",
          // no data at cursor
          emptyString: "No velocity data",
          // see explanation below
          angleConvention: "meteoCW",
          // display cardinal direction alongside degrees
          showCardinal: false,
          // one of: ['m/s', 'k/h', 'kt']
          speedUnit: "m/s",
          // direction label prefix
          directionString: "",
          // speed label prefix
          speedString: ""
        },
        data: this.data_obj, // see demo/*.json, or wind-js-server for example data service
        // OPTIONAL
        minVelocity: 0, // used to align color scale
        maxVelocity: 15, // used to align color scale
        velocityScale: 0.01, // modifier for particle animations, arbitrarily defaults to 0.005
        colorScale: this.getColorScale(), // define your own array of hex/rgb colors
        onAdd: null, // callback function
        onRemove: null, // callback function
        opacity: 0.97, // layer opacity, default 0.97
        particleMultiplier: 3/300,
        particleAge: 50,
        lineWidth: 1.5,

        // optional pane to add the layer, will be created if doesn't exist
        // leaflet v1+ only (falls back to overlayPane for < v1)
        paneName: this.pane_name,
      });
    return [velocityLayer];
  }

  async updateLayers(layer_group) {
    const current_layers = await layer_group.getLayers();
    if (current_layers.length == 0) {
      const new_layers = await this.createLayers();
      new_layers.forEach(layer => layer_group.addLayer(layer));
    }
    else {
      current_layers[0].setData(this.data_obj);
    }
  }

  async updateAltitude(layer_group, altitude) {
    this.altitude = altitude;
    const current_layers = await layer_group.getLayers();
    if (current_layers.length === 0) {
      return;
    }
    const uv = this.calcSkyUV(this.altitude);
    this.data_obj[0].data = uv.u;
    this.data_obj[1].data = uv.v;
    current_layers[0].setData(this.data_obj)
  }

  calcSkyUV(altitude) {
    const Zo = 10.0;
    return {
      u: this.Au.map((u, idx) => u * Math.log(altitude / Zo) + this.Bu[idx]),
      v: this.Av.map((v, idx) => v * Math.log(altitude / Zo) + this.Bv[idx])
    };
  }
}

L.ShioVelocityLayer = L.VelocityLayer.extend({
  _initMouseHandler: function(voidPrevious) {
    if (voidPrevious) {
      this._map.removeControl(this._mouseControl);
      this._mouseControl = false;
    }
    if (!this._mouseControl && this.options.displayValues) {
      var options = this.options.displayOptions || {};
      options["leafletVelocity"] = this;
      this._mouseControl = L.control.shioVelocity(options).addTo(this._map);
    }
  },
});
L.shioVelocityLayer = function(options) {
  return new L.ShioVelocityLayer(options);
};

L.Control.ShioVelocity = L.Control.Velocity.extend({
  _onMouseMove: function(e) {
    var self = this;
    var pos = this.options.leafletVelocity._map.containerPointToLatLng(
      L.point(e.containerPoint.x, e.containerPoint.y)
    );
    var gridValue = this.options.leafletVelocity._windy.interpolatePoint(
      pos.lng,
      pos.lat
    );
    var htmlOut = "";

    if (
      gridValue &&
      !isNaN(gridValue[0]) &&
      !isNaN(gridValue[1]) &&
      gridValue[2]
    ) {
    var deg = self.vectorToDegrees(gridValue[0], gridValue[1], this.options.angleConvention);
    var cardinal = this.options.showCardinal ? ` (${self.degreesToCardinalDirection(deg)}) ` : '';

		htmlOut = `<font size="+1"><strong> ${this.options.velocityType} ${
			this.options.directionString
		} </strong> ${deg.toFixed(2)}°${cardinal}<br><strong> ${this.options.velocityType} ${
			this.options.speedString
		} </strong> ${self
			.vectorToSpeed(gridValue[0], gridValue[1], this.options.speedUnit)
			.toFixed(2)} ${this.options.speedUnit}
      </font>`;
    } else {
      htmlOut = this.options.emptyString;
    }

    self._container.innerHTML = htmlOut;
  }
});

L.control.shioVelocity = function(options) {
  return new L.Control.ShioVelocity(options);
};
