L.SVG.Pattern = L.SVG.extend({

  _superUpdateStyle: L.SVG.prototype._updateStyle,

  _updateStyle: function (layer) {
    this._superUpdateStyle(layer);

    if (layer.options.fill && layer.options.patternId) {
      if (!this._defs) {
        this._defs = L.SVG.create('defs');
        this._container.appendChild(this._defs);
      }
      /*
      const pattern = this._createDotPattern(
        layer.options.patternId,
        layer.options.dotSize,
        layer.options.dotSpace,
        layer.options.patternColor,
        layer.options.patternOpacity);
      */
      const pattern = this._createStripePattern(
        layer.options.patternId,
        layer.options.patternArray,
        layer.options.patternAngle,
        layer.options.patternScale,
        layer.options.patternColor,
        layer.options.patternOpacity);
      this._defs.appendChild(pattern);
      layer._path.setAttribute('fill', `url(#${layer.options.patternId})`);
    }
  },

  _createDotPattern: function (pattern_id, 
                      dot_size, dot_space, color, opacity) {
    let pattern = document.getElementById(pattern_id);
    if (pattern)
      return pattern;
    const pattern_size = dot_size + dot_space;
    pattern = this._createPatternElement(pattern_id, pattern_size);
    this.fillPatternWithDots(pattern, dot_size, color, opacity);
    return pattern;
  },

  _createStripePattern: function (pattern_id, 
                          pattern_array, pattern_angle, pattern_scale, color, opacity) {
    let pattern = document.getElementById(pattern_id);
    if (pattern)
      return pattern;
    const pattern_size = pattern_array.length;
    pattern = this._createPatternElement(pattern_id, pattern_size, pattern_angle, pattern_scale);

    if (pattern_array.every(x => x === 1))
      this.fillPatternWithColor(pattern, color, opacity);
    else
      this.fillPatternWithStripes(pattern, pattern_array, 1, color, opacity);
    return pattern;
  },

  _createPatternElement: function(id, size, angle=0.0, y_scale=1.0) {
    let pattern = L.SVG.create('pattern');
    pattern.setAttribute('id', id);
    pattern.setAttribute('x', 0);
    pattern.setAttribute('y', 0);
    pattern.setAttribute('patternUnits', 'userSpaceOnUse');
    pattern.setAttribute('width', size);
    pattern.setAttribute('height', size);

    let transform_values = [];
    if (Number.EPSILON < Math.abs(angle))
      transform_values.push(`rotate(${angle})`);
    if (Number.EPSILON < Math.abs(y_scale - 1.0))
      transform_values.push(`scale(1 ${y_scale})`);
    if (transform_values.length)
      pattern.setAttribute('patternTransform', transform_values.join(' '));
    return pattern;
  },

  fillPatternWithColor: function(pattern, color, opacity) {
    const width = pattern.getAttribute('width');
    const height = pattern.getAttribute('height');
    let rect = L.SVG.create('rect');
    rect.setAttribute('width', width);
    rect.setAttribute('height', height);
    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('fill', color);
    rect.setAttribute('fill-opacity', opacity);
    pattern.appendChild(rect);
  },

  fillPatternWithStripes: function(pattern, 
                            stripes_array, line_width, color, opacity) {
    const width = pattern.getAttribute('width');
    const height = pattern.getAttribute('height');
    for (let i = 0; i < height; ++i) {
      if (! stripes_array[i % stripes_array.length])
        continue;
      var line = L.SVG.create('line');
      line.setAttribute('x1', 0);
      line.setAttribute('y1', i + line_width/2);
      line.setAttribute('x2', width);
      line.setAttribute('y2', i + line_width/2);
      line.setAttribute('stroke-width', line_width);
      line.setAttribute('stroke', color);
      line.setAttribute('stroke-opacity', opacity);
      pattern.appendChild(line);
    }
  },

  fillPatternWithDots: function(pattern, 
                        dot_size, color, opacity) {
    var rect = L.SVG.create('rect');
    rect.setAttribute('width', dot_size);
    rect.setAttribute('height', dot_size);
    rect.setAttribute('x', 0);
    rect.setAttribute('y', 0);
    rect.setAttribute('fill', color);
    rect.setAttribute('fill-opacity', opacity);
    pattern.appendChild(rect);
  },

});

L.svg.pattern = function(options) {
  return new L.SVG.Pattern(options);
}