export default {
  getSurfaceString : function(model_id, surface_id) {
    if (!model_id.length || !surface_id.length) return '';
    if (model_id === 'Obs')
      return surface_id;
    else {
      const surface_items = surface_id.split('/');
      return surface_items.length < 2 ? '' : surface_items[1];
    }
  },

  createInfoTitle : function(model_id, surface_id) {
    const surface = this.getSurfaceString(model_id, surface_id);
    const re = new RegExp(/^\d+$/);
    if (surface === 'SURFACE')  return '地上';
    else if (re.test(surface)) return `${surface}hPa`;
    else if (surface === 'INDEX')  return '安定指数';
    else if (surface === 'NOWC') return '高解像度降水ナウキャスト';
    else if (surface === 'HIMAWARI/B13') return '赤外画像 (バンド: B13 / 波長: 10.4 μm)';
    else if (surface === 'HIMAWARI/B03') return '可視画像 (バンド: B03 / 波長: 0.64 μm)';
    else if (surface === 'HIMAWARI/B08') return '水蒸気画像 (バンド: B08 / 波長: 6.2 μm)';
    else if (surface === 'HIMAWARI/REP') return 'TrueColor再現画像';
    else if (surface === 'HIMAWARI/SND') return '雲長高度強調画像 (バンド: B13 + B03)';
  },

  createInfoBody : function(model_id, surface_id) {
    const surface = this.getSurfaceString(model_id, surface_id);
    if (surface === 'SURFACE') {
      return '雲量は2,000mまでの下層雲、7,000mまでの中層雲、それ以上の上層雲の予想。' +
        'この全球モデルでは個々の積乱雲まで予想することはできない。' +
        'したがって雨量も局地的な豪雨はほぼ予測不可能であることは念頭に置いて利用する。';
    }
    else if (surface === '950') {
      return "上空約550mの天気図。東京スカイツリーくらいの高さ。" +
        "<dl><p id='info-element'>相当温位</p>" +
        '<dt>線状降水帯は850hPaより下層の950hPaの相当温位に注目する。355Kが豪雨の目安。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>風</p>" +
        '<dt>高相当温位域で20m/s以上の強風の収束域に線状降水帯が発生する危険がある。</dt>' +
        '</dl>';
    }
    else if (surface === '850') {
      return "上空約1500mの天気図。ちょうど丹沢山と同じくらいの高さの天気図。" +
        "<dl><p id='info-element'>気温</p>" +
        '<dt>標高1500mまでの山の気温として服装、装備などの参考にする。' +
            '地上最低最高気温の目安になる(地域、季節によるが+5℃すると最低、+15℃すると最高)。' +
            '-6℃のラインが地上での雪の目安。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>相当温位</p>" +
        '<dt>相当温位線が混み合っているいるところに前線が予想される。' +
            '梅雨から夏季は330K以上は要注意、特に340K以上のエリアは危険。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>風</p>" +
        '<dt>2000m以下の山の稜線の風速として参考にできる。' +
            '地上低気圧の前面で南寄りの25m/s以上の強風(下層ジェット)が予想される場合は大雨の可能性が大きい。' +
            '特に梅雨期は、高相当温位＋下層ジェット＝豪雨の危険。</dt>' +
        '</dl>';
    }
    else if (surface === '700') {
      return "上空約3000mの天気図。ちょうど日本アルプスの稜線と同じくらいの高さの天気図。" +
        "<dl><p id='info-element'>気温</p>" +
        '<dt>0℃ラインで雪の可能性を知ることができるなど、登山装備や服装の参考にできる。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>風</p>" +
        '<dt>3000m級の山の稜線での風に相当する。平均風速であるため瞬間的には2倍ほど強くなる場合があるので注意が必要。</dt>' +
        '</dl>';
    }
    else if (surface === '500') {
      return "上空約5500mの天気図。大雪や不安定な天気の原因となる寒気の南下や大局的な天気傾向を掴むことができる。" +
        "<dl><p id='info-element'>気温</p>" +
        '<dt>冬季-36℃以下が豪雪、夏季-6℃以下が大雨、落雷の目安。' +
            'その他の季節も含めて悪天候の恐れがある目安の気温に色付けしている。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>風</p>" +
        '<dt>強風軸(冬季は寒帯前線ジェット気流に相当)が南に大きく蛇行すると寒気が入りやすい。' +
            '南北に分流した北側にブロッキング高気圧(梅雨期のオホーツク海高気圧など)、' + 
            '南側に寒冷(切離)低気圧が出現し、しばらく停滞する。' +
            'また、梅雨前線は強風軸の直下付近に位置することが多い。</dt>' +
        '</dl>' +
        "<dl><p id='info-element'>高度</p>" +
        '<dt>色付けされた5800m以上が夏の太平洋高気圧圏内の目安。このエリアが日本を覆えば梅雨明けが予想される。' +
            '等高線が南に張り出したところが気圧の谷となり、谷が深いほど悪天候になりやすい。</dt>' +
        '</dl>'; 
    }
    else if (surface === '300' || surface === '250' || surface === '200') {
      return '300hPaは上空約9,600m、250hPaは10,000m、200hPaは12,000mの天気図。' +
            'ジェット気流や太平洋高気圧やチベット高気圧など背の高い高気圧を見るための気圧面。' +
        "<dl><p id='info-element'>風</p>" +
        '<dt>北側の寒帯前線ジェット気流と南側の亜熱帯ジェット気流と分流した3つのジェット気流が存在することが多い。' +
          '冬季は亜熱帯ジェット気流は300hPaでは現れない場合が多いので、250hPa、200hPaで判断する。' +
          '寒帯前線ジェット気流が南に蛇行すると寒気の南下が予想される。亜熱帯ジェット気流は台風進路や梅雨前線に影響する。' +
          '亜熱帯ジェットは北上しがチベット高原にぶつかると南北に分流し、チベット高原の北側を迂回する第3の中間系ジェットが現れる。' +
          'オホーツク海上空で合流し梅雨の原因となるオホーツク海高気圧を形成する。' +
          '亜熱帯ジェットがさらに北上すると分流が解消され梅雨が明ける。</dt>' +
        '</dl>' + 
        "<dl><p id='info-element'>高度</p>" +
        '<dt>300hPaの色付けされた9720m以上がチベット高気圧圏内の目安。太平洋高気圧とチベット高気圧が重なりあうと猛暑。</dt>' +
        '</dl>';
    }
    else if (surface === 'INDEX') {
      return "KINX、SSIともに大気の安定指数" +
        "<dl><p id='info-element'>K指数(KINX)</p>" +
        '<dt>熱雷の予測に適した指数。' +
        '<ul>雷雨の可能性' +
        '<li>  ～15:       無</li>' +
        '<li>15～20:      20%</li>' +
        '<li>21～25:  20～40%</li>' +
        '<li>26～30:  20～60%</li>' +
        '<li>31～35:  60～80%</li>' +
        '<li>36～40:  80～90%</li>' +
        '<li>41～  : ほぼ100%</li>' +
        '<ul></dt>' +
        '</dl>' +
        "<dl><p id='info-element'>ショワルター安定指数(SSI)</p>" +
        '<dt> 3以下で雷雨の可能性あり。' +
        '<ul>予想される雷雨の程度' +
        '<li>+3 ～   : 雷雨の可能性は低い</li>' +
        '<li>+1 ～ +3: 弱い雷雨の可能性あり</li>' +
        '<li>-3 ～  0: 激しい雷雨の可能性あり</li>' +
        '<li>-6 ～ -4: 激しい雷雨の予想</li>' +
        '<li>   ～ -6: 猛烈な雷雨の可能性大</li>' +
        '<ul></dt>' +
        '</dl>';
    }
    else if (surface === 'NOWC') {
      return '高解像度降水ナウキャスト。降水実況と1時間後までの降水量予測。' +
          '<p><img src="./icon/legend_jp_normal_hrpns.svg" width="80%" style="background-color:#FFF"></img></p>';
    }
    else if (surface === 'HIMAWARI/B13') {
      return '温度を濃淡表示した画像。雲長高度が高い雲ほど白く表される。下層雲は判別が難しいことも。';
    }
    else if (surface === 'HIMAWARI/B03') {
      return '可視光の反射強度を画像化。昼間のみ利用可能。雲の影も表現されるため積乱雲など立体的に見える。';
    }
    else if (surface === 'HIMAWARI/B08') {
      return '赤外バンドの中で特に水蒸気の吸収を受けるバンドを利用。対流圏上層の水蒸気量が多いほど白く表現される。';
    }
    else if (surface === 'HIMAWARI/REP') {
      return '青、緑、赤の可視3バンドに近赤外、赤外バンドを利用して人の目で見たような色を再現した画像。' +
            '積雪、植生、黄砂、火山の噴煙なども判別できる。';
    }
    else if (surface === 'HIMAWARI/SND') {
      return '日中は可視画像、夜間は赤外画像を表示し、その上に雲長高度が高い雲を色付けした画像。' +
            '赤色ほど雲長高度が高く積乱雲が含まれる可能性がある。';
    }
    else {
      return ""; 
    }
  }
}
