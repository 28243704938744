<template>
  <div id="map-wrapper" :style="styles">
    <v-btn icon
      fixed bottom right 
      @click.stop="isMenuOpened=!isMenuOpened"
      style="bottom: 8px; right: 10px; z-index:100"
      >
      <v-icon size="40px" color="#fff">{{ iMenu }}</v-icon>
    </v-btn>

    <v-card id='map' color='#11233D'></v-card>

    <v-navigation-drawer 
      app
      right
      temporary
      bottom
      color="#21212140"
      v-model="isMenuOpened" 
      class="d-flex flex-row-reverse"
      >
      <v-card
        color="rgba(25,118,210,0.5)" 
        class="mr-10 mb-10 overflow-y-auto menu-scrollbar"
        width="100%"
        :height="$vuetify.breakpoint.name === 'xs' ? '' : $vuetify.breakpoint.height - 150"
        elevation=0
        >
        <v-treeview
          :items="treeItems"
          item-key="id"
          dark
          dense
          :open='openIds'
          @update:open="onOpen"
          >
          <template v-slot:label="{ item }">
            <span @click="selectItem(item)">{{ item.name }}</span>
          </template>
          <template v-slot:prepend="{ item, }">
            <v-icon v-if="item.select_type === 'radio'"  @click="selectItem(item)">
              {{ item.selected ? iRadioboxMarked : iRadioboxBlank }}
            </v-icon>
            <v-icon v-else-if="item.select_type === 'checkbox'"  @click="selectItem(item)">
              {{ item.selected ? iCheckboxMarked : iCheckboxBlank }}
            </v-icon>
            <v-icon v-else>
              {{ iFolder }}
            </v-icon>
          </template>
        </v-treeview>
      </v-card>
    </v-navigation-drawer>

    <v-dialog 
      dark scrollable 
      v-model="isInfoShown"
      width="500px" max-width="90%"
    > 
      <v-card 
        dark
        max-height="50vh"
      >
        <v-card-title>{{ infoTitle }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text 
          :class="$vuetify.breakpoint.name === 'xs' ? '' : 'menu-scrollbar'"
          v-html="infoBody"></v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            color="grey darken-1"
            text
            @click="isInfoShown=!isInfoShown"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      dark
      v-model="isSettingShown"
      width="400px"
    >
      <v-card>
        <v-card-title>設定</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="mt-5">
          <v-row>
            <v-col cols="6">
              <v-subheader class="pl-0">
                ベースマップ
              </v-subheader>
              <v-radio-group v-model="selectedBaseMapType"
                             v-on:change="onBaseMapChange">
                <v-radio v-for="m in availableBaseMaps" :key="m.key"
                         :label="m.label" :value="m.key" />
              </v-radio-group>
            </v-col>
            <v-col cols="6">
              <v-subheader class="pl-0">
                陰影起伏合成
              </v-subheader>
              <v-checkbox v-for="m in availableHillShadeMaps"
                        :key="m.key"
                        :label="m.label"
                        :input-value="selectedHillShadeMapTypes.includes(m.key)"
                        v-on:change="onHillShadeMapChange(m.key)" />
            </v-col>
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-subheader class="pl-0">
                100名山
              </v-subheader>
            </v-col>
          </v-row>
          <v-row class="d-flex justify-space-between mb-3">
              <v-checkbox v-for="m in mountainMarkersShown"
                :key="m.id"
                :label="m.name"
                :input-value="m.checked"
                @change="showMountainMarkers(m.id, $event)"
                />
          </v-row>
          <v-divider></v-divider>
          <v-row>
            <v-col cols="12">
              <v-subheader class="pl-0">
                時間ステップ
              </v-subheader>
              <v-slider
                v-model="sliderStepHour"
                inverse-label label="(Hour)"
                :tick-labels="Object.values(stepHour)"
                min="0"
                :max="Object.keys(stepHour).slice(-1)[0]"
                step="1"
                :ticks-size="Object.keys(stepHour).length"
              ></v-slider>
            </v-col>
          </v-row>
          </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
  import L from 'leaflet'
  import 'leaflet/dist/leaflet.css'
  import 'leaflet.tilelayer.colorfilter'; // MIT
  import 'leaflet-velocity';              // MIT
  import './lib/Leaflet.streetlabels/ctxtextpath';          // MIT
  import './lib/Leaflet.streetlabels/L.LabelTextCollision'; // MIT
  import './lib/Leaflet.streetlabels/Leaflet.streetlabels'; // ISC
  import './lib/leaflet.timedimension/leaflet.timedimension.control.min.css'; // MIT
  import './lib/leaflet.timedimension/leaflet.timedimension.min.js';          // MIT
  import './lib/Leaflet.TileLayer.GL.js';                   // BEER-WARE
  import './lib/L.TileLayer.NonFlilckering';
  import './lib/L.SVG.Pattern';
  import './lib/leaflet-slider/leaflet-slider.js';
  import './lib/leaflet-slider/leaflet-slider.css';

  import dayjs from 'dayjs';
  import utc from 'dayjs/plugin/utc';
  dayjs.extend(utc);

  import { 
    mdiMenu, 
    mdiRadioboxMarked, 
    mdiRadioboxBlank, 
    mdiCheckboxMarkedOutline, 
    mdiCheckboxBlankOutline, 
    mdiFolderOutline,
  } from '@mdi/js';

  import MenuTree from '@/components/MenuTree';
  import Info from './InfoContent';
  import { PixiMarkersLayer } from '@/components/lib/PixiMarkersLayer';

  import { Util } from './util/util';
  import { LayerFactory } from './GeoTiffLayer/LayerFactory';
  import { TopoJSON } from './util/TopoJSON';

  delete L.Icon.Default.prototype._getIconUrl;
  L.Icon.Default.mergeOptions({
      iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
      iconUrl: require('leaflet/dist/images/marker-icon.png'),
      shadowUrl: require('leaflet/dist/images/marker-shadow.png')
  });

  const FragmentShader_SQRT =   // y = sqrt( (x - 0.15) / 0.85 )
`
void main(void) {
  vec4 texelColor = texture2D(uTexture0, vec2(vTextureCoords.s, vTextureCoords.t));
  float alpha = sqrt( (texelColor.r - 0.15) / 0.85);
  if (alpha < 0.0) alpha = 0.0;
  gl_FragColor = vec4(vec3(1.0), alpha);
}`;

  const FragmentShader_LINEAR =   // y = x
`
void main(void) {
  vec4 texelColor = texture2D(uTexture0, vec2(vTextureCoords.s, vTextureCoords.t));
  float alpha = texelColor.r;
  gl_FragColor = vec4(vec3(1.0), alpha);
}`;

  const FragmentShader_PassThrough = 
`
void main(void) {
  gl_FragColor = texture2D(uTexture0, vTextureCoords);
}`;


  export default {
    name: 'AppMain',
    components: {
    },
    props: {
      timeControlWidth: {
        type: String,
        default: '400px',
      },
      timeSliderWidth: {
        type: String,
        default: '300px',
      },
      timeSliderbarWidth: {
        type: String,
        default: '200px',
      },
    },
    computed: {
      styles() {
        return {
          '--time-control-width': this.timeControlWidth,
          '--time-slider-width': this.timeSliderWidth,
          '--time-sliderbar-width': this.timeSliderbarWidth,
        }
      },
    },
    data() {
      return {
        geoTiffLayer: {},

        selectedBaseMapType: null, // to-be -filled later
        selectedHillShadeMapTypes: ['HillShadeMap'],

        // 'layer_obj' is to be filled later.
        availableBaseMaps: [
          { key:'Pale', label:'淡色地図', url_key:'pale', layer_obj:null,
            filter: [
              'hue:         0deg',
              'saturate:     60%',
              'brightness:   52%',
              'contrast:    450%',
            ]
          },
          { key:'Std',  label:'標準地図', url_key:'std', layer_obj:null,
            filter: [
              'hue:         0deg',
              'saturate:     60%',
              'brightness:   50%',
              'contrast:    300%',
              //'grayscale:90%',
            ],
          },
          { key:'Blank',label:'白地図', url_key:'blank', layer_obj:null,
            filter: [
              'invert:100%',
            ],
          },
         {
           key:'Slope',label:'傾斜地図', url_key:'slopemap', layer_obj:null,
           filter: [
              'brightness:   50%',
              'contrast:    100%',
           ],
         },
        ],
        availableHillShadeMaps: [
          {
            key: 'HillShadeMap', label:'日本', url_key:'hillshademap', layer_obj:null,
            minNativeZoom: 2, maxNativeZoom: 16,
            minZoom: 0, maxZoom: undefined,
            filter: [
                'contrast:    100%',
                'brightness:   100%',
                'opacity:80%',
            ],
          },
          {
            key: 'EarthHillShade', label:'全球', url_key:'earthhillshade', layer_obj:null,
            minNativeZoom: 0, maxNativeZoom: 8,
            minZoom: 0, maxZoom: undefined,
            filter: [
                'contrast:    100%',
                'brightness:   100%',
                'opacity:80%',
            ],
          },
        ],
        iMenu: mdiMenu,
        iRadioboxMarked: mdiRadioboxMarked,
        iRadioboxBlank: mdiRadioboxBlank,
        iCheckboxMarked: mdiCheckboxMarkedOutline, 
        iCheckboxBlank: mdiCheckboxBlankOutline, 
        iFolder: mdiFolderOutline,

        infoTitle: '',
        infoBody: '',
        selected: { model: undefined, surface: undefined },
        selectedLayers: [],
        treeItems: [],
        isMenuOpened: false,
        isInfoShown: false,
        allLayers: {},
        panes: {},
        map: null,
        validate_time: 0,
        reference_time: 0,
        basetime: 0,
        currenttime: 0,
        fragmentShader: {
          'HIMAWARI/B13': FragmentShader_SQRT,
          'HIMAWARI/B03': FragmentShader_SQRT,
          'HIMAWARI/B08': FragmentShader_LINEAR,
          'HIMAWARI/REP': FragmentShader_PassThrough,
          'HIMAWARI/SND': FragmentShader_PassThrough,
        },
        colorLayersRenderer : null,
        cloudLayersRenderer : null,
        lineLabelsRenderer : null,
        forecast_hour: 0,
        enableTouchend: true,
        ordered_base_valid_times: [],
        isSettingShown: false,
        sliderStepHour: 0,
        stepHour: { 0: '0', 1: '1', 2: '3', 3: '6', 4: '12', 5: '24' },
        openIds: [],
        selectedModelId: undefined,
        selectedSurfaceId: undefined,
        itemIdsToShow: [],
        mountainMarkersShown: [
          { id:100, name:'百名山', checked:false },
          { id:200, name:'二百名山', checked:false },
          { id:300, name:'三百名山', checked:false },
        ],
        pixiMarkersLayer: undefined,
        locationMarker: undefined,
        locationPopup: undefined,
        altSlider: undefined,
      }
    },


    watch: {
      isSettingShown(val) {
        val || this.onCloseSettingDialog();
      },
    },


    created: function() {
      let model_id = localStorage.getItem('model');
      if (!model_id) model_id = 'GFS';
      let surface_id = localStorage.getItem('surface');
      if (!surface_id) surface_id = 'HIMAWARI/B13';
      let element_ids = []
      try {
        element_ids = JSON.parse(localStorage.getItem('element'));
      } catch (error) {
        console.log(error);
      }
      this.selectedModelId = model_id;
      this.selectedSurfaceId = surface_id;
      this.openIds = [model_id, surface_id];

      this.treeItems = MenuTree.createMenuTree();
      this.treeItems.forEach(model => {
        if (!model.children) return;
        model.children.forEach(surface => {
          surface.selected = surface.id === this.selectedSurfaceId;
          if (!surface.children || !element_ids) return;
          surface.children.forEach(element => {
            element.selected = element_ids.includes(element.id);
          });
        });
      });

      const step = localStorage.getItem('sliderStepHour');
      this.sliderStepHour = step ? step : 0;
      const remembered = localStorage.getItem('baseMapType');
      if (this.availableBaseMaps.some(map_def => map_def.key == remembered))
        this.selectedBaseMapType = remembered;
      else
        this.selectedBaseMapType = this.availableBaseMaps[0].key;
      this.selectedHillShadeMapTypes = JSON.parse(localStorage.getItem('hillShadeMapTypes'));
      if (! this.selectedHillShadeMapTypes)
        this.selectedHillShadeMapTypes = [];
    },


    mounted: async function () {
      document.documentElement.addEventListener('touchstart', evt => {
        if (2 <= evt.touches.length) evt.preventDefault();
      }, { passive: false });
      document.documentElement.addEventListener('touchend', evt => {
        if (this.enableTouchend) {
          this.enableTouchend = false;
          setTimeout(() => { this.enableTouchend = true; }, 500);
        }
        else {
          evt.preventDefault();
        }
      }, false);
      window.addEventListener('resize', () => this.calcTimeControlWidth(window.innerWidth));

      await this.createMap();

      let f_luminance = Util.Luminance;
      this.lineLabelsRenderer = new L.StreetLabels({
        collisionFlg : true,
        propertyName : 'contour',
        showLabelIf: function(layer) {
          if (layer.properties.color) {
            const luminance = f_luminance(layer.properties.color);
            this.options.fontStyle.fillStyle = layer.properties.color;
            this.options.fontStyle.strokeStyle = luminance < 128 ? 'white' : 'black';
          }
          else {
            this.options.fontStyle.fillStyle = 'black';
            this.options.fontStyle.strokeStyle = 'white';
          }
          return true;
        },
        fontStyle: {
          dynamicFontSize: false,
          fontSize: 12,
          fontSizeUnit: "px",
          lineWidth: 2.0,
          fillStyle: "black",
          strokeStyle: "white",
        },
      });

      this.initPanesAndLayerGroups();

      // initial display
      this.itemIdsToShow = this.getItemIdsToShow();
      this.showSelectedLayers();
      this.calcTimeControlWidth(window.innerWidth);
    },


    methods: {
      calcTimeControlWidth: function(windowWidth) {
        const controlWidth = windowWidth * 0.96;
        this.timeControlWidth = `${controlWidth}px`;
        this.timeSliderWidth = `${controlWidth - 85}px`;
        this.timeSliderbarWidth = `${controlWidth - 105}px`;
      },
      showMountainMarkers: function(id, checked) {
        if (!checked) {
          this.pixiMarkersLayer.removeMarkers(id);
          return;
        }

        const paneName = 'mountain_markers';
        let opts = {
          pane: paneName,
          markerWidth: 28,
          mouseOverStyle: 'leaflet-interactive',
          zoomDuration: 240,
          nonScalingZoomLevel: 8,
          selectedMarkerScaling: 1.5,
        };
        let pane = this.map.getPane(paneName);
        if (!pane) {
          pane = this.map.createPane(paneName);
          pane.style.zIndex = 250;
        }

        if (!this.pixiMarkersLayer)
          this.pixiMarkersLayer = new PixiMarkersLayer(opts);
        this.pixiMarkersLayer
            .addResource(id, `/icon/${id}.png`)
            .addSelectedResource(id, `/icon/s${id}.png`);
        this.pixiMarkersLayer.load(() => {
          this.pixiMarkersLayer.getJSON(`/mountain${id}.json`, (markers) =>  {
            if (!this.pixiMarkersLayer.hasPixiOverlay()) {
              this.pixiMarkersLayer.createLayer(markers);
              this.pixiMarkersLayer.addToMap(this.map);
            }
            else {
              this.pixiMarkersLayer.addMarkers(markers);
            }
          });
        });
      },

      _createPaneAndLayerGroup: function(element) {
        this.allLayers[element.id] = L.layerGroup([]).addTo(this.map);
        const found = Object.values(this.panes)
                      .find(x => x.renderer.options.pane === element.pane);
        if (found) {
          this.panes[element.id] = {
            pane     : found.pane,
            renderer : found.renderer,
            zIndex   : element.zIndex,
            opacity  : element.opacity,
          };
        }
        else {
          this.panes[element.id] = {
            pane     : this.map.createPane(element.pane),
            renderer : L.svg.pattern({ pane: element.pane, padding: 0.0 }),
            zIndex   : element.zIndex,
            opacity  : element.opacity,
          };
        }
      },

      initPanesAndLayerGroups: function() {
        this.treeItems.forEach(model => {
          model.children.forEach(surface => {
            if (surface.children) {
              surface.children.forEach(element => {
                this._createPaneAndLayerGroup(element);
              });
            }
            else {
              this._createPaneAndLayerGroup(surface);
            }
          });
        });
      },

      onOpen(items) {
        console.log(items)
      },

      resetRadioRecursive: function(root, group) {
        const children = Array.isArray(root) ? root : root.children;
        if (!children) return;
        children.map(child => {
          if (child.select_type==='radio' &&
              child.radio_group === group &&
              child.selected) {
                child.selected = false;
              }
          this.resetRadioRecursive(child, group);
        });
      },

      getModelIdBySurfaceId: function(surface_id) {
        const parentNodeId = this.treeItems
          .filter(model => model.children.filter(surface => surface.id === surface_id).length)
          .map(model => model.id);
        if (parentNodeId.length)  return parentNodeId[0];
        else                      return undefined;
      },

      getItemIdsToShow: function() {
        if (!this.selectedModelId || !this.selectedSurfaceId) return [];
        const model_node = this.treeItems.find(model => model.id === this.selectedModelId);
        if (!model_node) return [];
        const surface_node = model_node.children.find(surface => surface.id === this.selectedSurfaceId);
        if (!surface_node)  return [];
        if (surface_node.children) {
          return surface_node
                  .children
                  .filter(element => element.selected)
                  .map(element => element.id);
        }
        else
          return [surface_node.id];
      },

      clearUnselectedLayers: function() {
        Object.keys(this.allLayers)
          .filter(id => !this.itemIdsToShow.includes(id))
          .forEach(id => {
            this.allLayers[id].clearLayers();
            delete this.geoTiffLayer[id];
          });
      },

      getAllCheckedElementIds: function() {
        let checked = [];
        this.treeItems.forEach(model => {
          model.children.forEach(surface => {
            if (surface.children) {
              const ids = surface.children.filter(e => e.selected).map(e => e.id);
              checked.push(...ids);
            }
          });
        });
        return checked;
      },

      selectItem: function(item) {
        if (item.select_type === 'radio' && !item.selected) {
          this.resetRadioRecursive(this.treeItems, item.radio_group);
          item.selected = true;
          this.selectedModelId = this.getModelIdBySurfaceId(item.id);
          this.selectedSurfaceId = item.id;
          this.openIds = [this.selectedModelId, this.selectedSurfaceId];
        }
        else if (item.select_type === 'checkbox')
          item.selected = !item.selected;
        else {
          let idx = this.openIds.indexOf();
          if (idx == -1)
            this.openIds.push(item.id);
          else
            this.openIds.splice(idx, 1);
        }

        this.itemIdsToShow = this.getItemIdsToShow();
        this.showSelectedLayers();

        localStorage.setItem('model', this.selectedModelId);
        localStorage.setItem('surface', this.selectedSurfaceId);
        localStorage.setItem('element', JSON.stringify(this.getAllCheckedElementIds()));
      },

      showSelectedLayers: function() {
        if (this.itemIdsToShow.length) {
          this.setDataPeriod(this.selectedModelId, this.itemIdsToShow[0]);
        }
        this.infoTitle = Info.createInfoTitle(this.selectedModelId, this.selectedSurfaceId);
        this.infoBody = Info.createInfoBody(this.selectedModelId, this.selectedSurfaceId);
        if (0 < this.itemIdsToShow.filter((id) => id.includes('SKYWIND')).length) {
          this.altSlider.addTo(this.map);
        } else {
          this.map.removeControl(this.altSlider);
        }
        console.log(this.selectedModelId,this.itemIdsToShow[0]);
      },

      lookupResolutionForZoom(model, zoom_level) {
        let resX =  (8 <= zoom_level) ? 0.02 :
                    (7 <= zoom_level) ? 0.05 :
                    (6 <= zoom_level) ? 0.10 :
                    (5 <= zoom_level) ? 0.25 :
                    (4 <= zoom_level) ? 0.50 :
                    (3 <= zoom_level) ? 1.00 :
                    (2 <= zoom_level) ? 1.50 : 2.00;
        let resY = resX;
        return [resX, resY];
      },

      parseModel: function(id) {
        const model_el = id.split('/');
        if (model_el.length == 2)
          return model_el[0];
        return 'GFS';
      },

      parseElement: function(id) {
        const model_el = id.split('/');
        if (model_el.length == 2)
          return model_el[1];
        return id;
      },

      getOrCreateGeoTiffLayer: function(id) {
        if (!Object.prototype.hasOwnProperty.call(this.geoTiffLayer, id) ||
            !this.geoTiffLayer[id]) {
          const element = this.parseElement(id);  // WIND, TMP_850, etc.
          this.geoTiffLayer[id] = LayerFactory.init(element);
        }
        return this.geoTiffLayer[id];
      },

      setPaneStyle: function(id) {
        const pane = this.map.getPane(this.panes[id].pane);
        pane.style.opacity = this.panes[id].opacity;
        pane.style.zIndex = this.panes[id].zIndex;
      },

      updateGFSLayers: async function(id, basetime, validtime) {
        if (this.selectedModelId === 'Obs')  return;
        const model = this.parseModel(id);
        const geotiff = this.getOrCreateGeoTiffLayer(id);
        const bounds = this.map.getBounds();
        const zoom_level = this.map.getZoom();
        const bounding_box = [bounds.getWest(), bounds.getSouth(), bounds.getEast(), bounds.getNorth()];
        const resolution = this.lookupResolutionForZoom(model, zoom_level);
        console.log(`zoom = ${zoom_level} / resolution = ${resolution}`);
        if (!await geotiff.getGeotiffData(model, basetime, validtime, bounding_box, resolution)) {
          this.allLayers[id].clearLayers();
          return;
        }
        geotiff.setColorRenderer(this.panes[id].renderer);
        geotiff.setLineLabelsRenderer(this.lineLabelsRenderer);
        await geotiff.updateLayers(this.allLayers[id]);
        this.setPaneStyle(id);
      },

      getJMATileUrl: function(id, basetime, validtime) {
        const base = basetime < validtime ? basetime : validtime;
        let props;
        if (id === 'NOWC') {
          props = {
            type:     'jmatile',
            element:  'nowc',
            area:     'none',
            band:     'surf',
            prod:     'hrpns',
            ext:      'png',
          };
        }
        else { //if (id === 'ひまわり') {
          // band/prod = B13/TBB 赤外画像、B03/ALBD 可視画像、B08/TBB 水蒸気画像、REP/ETC トゥルーカラー再現画像、SND/ETC 雲頂強調画像
          props = {
            type:     'himawari',
            element:  'satimg',
            area:     'fd',
            ext:      'jpg',
          };
          if      (id.endsWith('B13')) { props.band = 'B13'; props.prod = 'TBB'; }
          else if (id.endsWith('B03')) { props.band = 'B03'; props.prod = 'ALBD';}
          else if (id.endsWith('B08')) { props.band = 'B08'; props.prod = 'TBB'; }
          else if (id.endsWith('REP')) { props.band = 'REP'; props.prod = 'ETC'; }
          else if (id.endsWith('SND')) { props.band = 'SND'; props.prod = 'ETC'; }
        }
        return `https://www.jma.go.jp/bosai/${props.type}/data/` +
                  `${props.element}/${base}00/${props.area}/${validtime}00/` +
                  `${props.band}/${props.prod}/{z}/{x}/{y}.${props.ext}`;
      },

      updateJMALayers: function(id, basetime, validtime) {
        if (this.selectedModelId !== 'Obs')  return;
        const url = this.getJMATileUrl(id, basetime, validtime);
        const fragment_shader = this.fragmentShader[id];
        let options = {
          pane: this.panes[id].renderer.options.pane,
          minZoom: 3,
          maxZoom: 12,
        };
        options.maxNativeZoom = id === 'NOWC' ? 10 : 6;
        if (fragment_shader !== undefined) {
          options.tileUrls = [url];
          options.fragmentShader = fragment_shader;
        }

        const existing_layers = this.allLayers[id].getLayers();
        if (existing_layers.length === 0) {
          let LTileLayerConstructor = L.tileLayer.nonflickering;
          let constructorArgs = [url, options];
          if (fragment_shader) {
            LTileLayerConstructor = L.tileLayer.gl;
            constructorArgs = [options];
          }
          const layer = LTileLayerConstructor(...constructorArgs);
          this.allLayers[id].addLayer(layer);
        }
        else {
          const layer = existing_layers[0];
          layer.updateUrl(url);
          layer.refresh();
        }
        this.setPaneStyle(id);
      },

      getOrderedBaseValidTimes: async function(url) {
        const response = await fetch(Util.timestampedUrl(url));
        const content_type = response.headers.get('Content-Type');
        if (content_type.includes('application/json')) {
          const json_data = await response.json();
          let base_valid_times = json_data.map(x => 
            [
              dayjs.utc(x.basetime, 'YYYYMMDDHHmmss').unix()*1000,
              dayjs.utc(x.validtime, 'YYYYMMDDHHmmss').unix()*1000
            ]);
          base_valid_times.sort((a, b) => {
            if (a[1] < b[1]) return -1;
            if (b[1] < a[1]) return 1;
          });
          return base_valid_times;
        }
        return undefined;
      },

      setDataPeriod: function(model, id, force_update=true) {
        if (model === 'Obs')  this.setJMATilePeriod(id, force_update);
        else                  this.setGFSPeriod(id, force_update);
      },

      setGFSPeriod: async function(id, force_update=true) {
        const current_times = this.map.timeDimension.getAvailableTimes();
        const url = `/tiff/${id}/targetTimes.json`;
        this.ordered_base_valid_times = await this.getOrderedBaseValidTimes(url);
        this.basetime = parseInt(this.ordered_base_valid_times[0][0]);
        let resampled_validtimes = this.resampleValidTimesWithStep(
                            this.ordered_base_valid_times,
                            this.stepHour[this.sliderStepHour] *60*60*1000);
        // Use validtimes within 1 hour later from reftime.
        resampled_validtimes.filter(valid => 60*60 <= valid);
        if (!Util.isSameArray(current_times, resampled_validtimes)) {
          const now = dayjs.utc();
          let current = resampled_validtimes.find(x => now < x) ||
                        resampled_validtimes.slice(-1)[0];
          const display_time = this.map.timeDimension.getCurrentTime();
          if (display_time) {
            current = resampled_validtimes.find(x => display_time <= x) ||
                      current;
          }
          await this.map.timeDimension.setCurrentAndAvailableTimes(current, resampled_validtimes);
        }
        else if (force_update){ // force update even if same array.
          // for firing timeload event.
          const current = this.map.timeDimension.getCurrentTime();
          const nearest = this.map.timeDimension.seekNearestTime(current);
          await this.map.timeDimension.setCurrentTime(nearest);
        }
      },

      setJMATilePeriod: async function(id, force_update=true) {
        id;
        const display_time = this.map.timeDimension.getCurrentTime();
        const current_times = this.map.timeDimension.getAvailableTimes();
        let url = 'https://www.jma.go.jp/bosai/himawari/data/satimg/targetTimes_fd.json';
        if (id === 'NOWC') {
          url = 'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N1.json';
        }
        const ordered_base_valid_times = await this.getOrderedBaseValidTimes(url);
        this.basetime = parseInt(ordered_base_valid_times[ordered_base_valid_times.length-1][0]);
        this.ordered_validtimes = ordered_base_valid_times.map(x => x[1]);
        if (id === 'NOWC') {
          url = 'https://www.jma.go.jp/bosai/jmatile/data/nowc/targetTimes_N2.json';
          const forecast_times = await this.getOrderedBaseValidTimes(url);
          this.basetime = parseInt(forecast_times[0][0]);
          this.ordered_validtimes = this.ordered_validtimes.concat(forecast_times.map(x => x[1]));
        }
        if (!force_update && this.basetime !== display_time) {  // if reload(force_update = false)
          await this.map.timeDimension.setCurrentAndAvailableTimes(this.basetime, this.ordered_validtimes);
        }
        else if (!Util.isSameArray(current_times, this.ordered_validtimes)) {
          let current = this.basetime;
          if (display_time) {
            current = this.ordered_validtimes.find(x => display_time <= x) ||
                      current;
          }
          await this.map.timeDimension.setCurrentAndAvailableTimes(current, this.ordered_validtimes);
        }
        else if (force_update) { // force update even if same array.
          // for firing timeload event.
          await this.map.timeDimension.setCurrentTime(display_time);
        }
      },

      onBaseMapChange: function() {
        this.availableBaseMaps.forEach(map_def => {
          if (this.map.hasLayer(map_def.layer_obj)) {
            this.map.removeLayer(map_def.layer_obj);
          }
        });
        let new_map_def = this.availableBaseMaps.find(
          map_def => map_def.key == this.selectedBaseMapType);
        this.map.addLayer(new_map_def.layer_obj);
        localStorage.setItem('baseMapType', this.selectedBaseMapType);
      },

      onHillShadeMapChange: function(key) {
        const idx_of_key = this.selectedHillShadeMapTypes.indexOf(key);
        if (0 <= idx_of_key)
          this.selectedHillShadeMapTypes.splice(idx_of_key, 1);
        else
          this.selectedHillShadeMapTypes.push(key);
        this.composeHillShade(this.selectedHillShadeMapTypes);
        localStorage.setItem('hillShadeMapTypes', JSON.stringify(this.selectedHillShadeMapTypes));
      },

      composeCoastLine: async function(url, pane_name) {
        let coastlinePane = this.map.createPane(pane_name);
        coastlinePane.style.zIndex = 500;
        async function getTopology(url) {
          let response = await fetch(url);
          return await response.json();
        }
        const topology = await getTopology(url);
        var geojson = new TopoJSON(topology, {
          pane: coastlinePane,
          style: function(feature){
            feature;
            return {
              color: "#000",
              opacity: 0.8,
              weight: 1.5,
            }
          }
        });
        geojson.addTo(this.map);
        this.map.attributionControl.addAttribution('Made with Natural Earth.');
      },

      composeHillShade: function(keys) {
        if (keys.includes('HillShadeMap')) {
          let EarthHillShadeDef = this.availableHillShadeMaps.find(
                                      map_def => map_def.key === 'EarthHillShade');
          EarthHillShadeDef.maxZoom = EarthHillShadeDef.maxNativeZoom;
        }
        let compPane = this.map.getPane('compPane');
        if (!compPane) {
          compPane = this.map.createPane('compPane');
          compPane.style.mixBlendMode = 'multiply';
          compPane.style.zIndex = 201;  // zIndex of tilelayer is 200.
        }
        this.availableHillShadeMaps.forEach(map_def => {
          if (keys.includes(map_def.key)) {
            if (!map_def.layer_obj) {
              map_def.layer_obj = L.tileLayer.colorFilter(
                `https://cyberjapandata.gsi.go.jp/xyz/${map_def.url_key}/{z}/{x}/{y}.png`,
                {
                  minNativeZoom: map_def.minNativeZoom,
                  maxNativeZoom: map_def.maxNativeZoom,
                  minZoom: map_def.minZoom,
                  maxZoom: map_def.maxZoom,
                  pane: compPane,
                  filter: map_def.filter,
                },
              );
            }
            if (!this.map.hasLayer(map_def.layer_obj))
              this.map.addLayer(map_def.layer_obj);
          }
          else if (map_def.layer_obj && 
                    this.map.hasLayer(map_def.layer_obj))
              this.map.removeLayer(map_def.layer_obj);
        });
      },
      
      createMap: function() {
        this.availableBaseMaps.forEach(map_def => {
          map_def.layer_obj = new L.tileLayer.colorFilter(
            'https://cyberjapandata.gsi.go.jp/xyz/' + map_def.url_key +
              '/{z}/{x}/{y}.png', {
                attribution: "<a href='https://maps.gsi.go.jp/development/ichiran.html' target='_blank'>地理院タイル</a>",
                filter: map_def.filter,
              }) });

        L.TimeDimension.include({   // Add custom method on L.TimeDimension.
          setCurrentAndAvailableTimes: function (time, times) {
            this._availableTimes = L.TimeDimension.Util.parseTimesExpression(times);
            this.setLowerLimit(this._availableTimes[0]);
            this.setUpperLimit(this._availableTimes.slice(-1));
            this.setCurrentTime(time);  // This fires 'timeloading' event.
            // Don't fire 'availabletimeschanged' event.
            console.log('current time and available times changed');
          }
        });
        this.map = L.map('map', {
            center: [37.2, 140.6],
            //maxBounds:[ [-90, 0], [90, 360] ],
            zoom: 9,
            zoomControl: true,
            attributionControl: true,
            preferCanvas : true,
            timeDimension: true,
            timeDimensionOptions: {
              currentTime: this.reference_time,
            },
        });
        let map_def = this.availableBaseMaps.find(
          map_def => map_def.key == this.selectedBaseMapType);
        this.map.addLayer(map_def.layer_obj);
        this.map.attributionControl.setPosition('bottomleft');

        this.composeHillShade(this.selectedHillShadeMapTypes);
        this.composeCoastLine('./coastline10.topojson', 'coastlineLayer');

        this.map.on("zoomend", this.onMapZoomEnd);
        this.map.on("moveend", this.onMapMoveEnd);
        this.map.on("locationfound", this.onMapLocationFound);
        this.map.on('locationerror', this.onMapLocationError);

        L.Control.TimeDimensionCustom = L.Control.TimeDimension.extend({  // Create subclass of L.Control.TimeDimension.
          _getDisplayDateFormat: function(date){
            const dayjs_date = dayjs(date);
            let weekday_color = dayjs_date.day() === 0 ? 'salmon' : dayjs_date.day() === 6 ? 'lightblue' : 'lightgray' 
            return dayjs_date.format('MM/DD') + 
                  `<font size=-1 color=${weekday_color}> ` + dayjs_date.format('ddd') + ' </font>' +
                  dayjs_date.format('HH:mm');
          },
          _getDisplayNoTimeError: function() {
            return '---';
          },
        });
        var timeDimensionControl = new L.Control.TimeDimensionCustom({
          timeDimension: this.map.timeDimension,
          position: "bottomright",
          speedSlider: false,
          timeZones: ["Local"],
          playerOptions: {
            transitionTime: 1000,
          },
        });
        this.map.addControl(timeDimensionControl);
        this.map.timeDimension.on('timeloading', this.onTimeDimensionLoading);
        this.map.timeDimension.on('timeload',  L.Util.throttle(this.onTimeDimensionLoad, 500));

        L.Control.CustomIcons = L.Control.extend({
          addIcon: function(icon_name, container) {
            let icon = L.DomUtil.create('img', `icon-${icon_name}`, container);
            icon.src = `./icon/${icon_name}.png`;
            icon.style.width = '36px';
            icon.style.margin = '3px';
            L.DomEvent
              .addListener(icon, 'click', L.DomEvent.stopPropagation)
              .addListener(icon, 'click', L.DomEvent.preventDefault)
              .addListener(icon, 'click', this[`_${icon_name}Clicked`], this);
            L.DomEvent.disableClickPropagation(container);
          },
          onAdd: function(map) {
            this._map = map;
            let container = L.DomUtil.create('div', 'leaflet-bar leaflet-bar-horizontal leaflet-bar-customicons');
            container.style.background = 'rgba(25, 25, 112, 0.6)';
            container.style.margin = 'auto 8px 2px auto';
            this.addIcon('mylocation', container);
            this.addIcon('reload', container);
            this.addIcon('info', container);
            this.addIcon('setting', container);
            return container;
          },
          onRemove: function(map) {
            map;
          },
          _menuClicked: function() {
            this.isMenuOpened = !this.isMenuOpened;
          }.bind(this),
          _settingClicked: function() {
            this.isSettingShown = !this.isSettingShown;
          }.bind(this),
          _infoClicked: function() {
            console.log("showInfo()");
            this.isInfoShown = !this.isInfoShown;
          }.bind(this),
          _reloadClicked: function() {
            if (this.selectedModelId && 0 < this.itemIdsToShow.length)
                this.setDataPeriod(this.selectedModelId, this.itemIdsToShow[0], false);
          }.bind(this),
          _mylocationClicked: function() {
            this.map.locate({setView: true, maxZoom: this.map.getZoom()});
          }.bind(this),
        });
        const control_icons = new L.Control.CustomIcons({position:'bottomright'});
        control_icons.addTo(this.map);

        this.altSlider = L.control.slider((value) => {
          this.onAltSliderChange(value);
        }, {
          position: 'bottomright',
          max: 100,
          min: 10,
          value: 10,
          step: 10,
          size: '250px',
          orientation:'vertical',
          id: 'slider',
          logo: '高度'
        });
      },

      resampleValidTimesWithStep: function(base_valid_times, time_step) {
        const basetime = base_valid_times[0][0];
        let prev_time = basetime;
        return base_valid_times
                .map(base_valid => base_valid[1])
                .filter(valid => {
                  if (time_step <= valid - prev_time) {
                    prev_time = valid;
                    return true;
                  }
                });
      },

      onCloseSettingDialog: function() {
        if (this.selectedModelId === 'Obs')  return;
        const resampled_validtimes = this.resampleValidTimesWithStep(
                              this.ordered_base_valid_times,
                              this.stepHour[this.sliderStepHour] *60*60*1000);
        resampled_validtimes.filter(valid => 60*60 <= valid);
        const current = this.map.timeDimension.getCurrentTime();
        const nearest = this.map.timeDimension.seekNearestTime(current);
        this.map.timeDimension.setCurrentAndAvailableTimes(nearest, resampled_validtimes);
        localStorage.setItem('sliderStepHour', this.sliderStepHour);
      },

      onMapZoomEnd: async function() {
        const zoom_level = this.map.getZoom();
        //if (this.map._locateOptions)
        //  this.map._locateOptions.maxZoom = zoom_level;
        this.map.getPane('coastlineLayer').style.display = 8 < zoom_level ? 'none' : '';
        
      },

      onMapMoveEnd: async function() {
        if (this.selectedModelId === 'Obs')  return;
        const current_time = this.map.timeDimension.getCurrentTime();
        let valid = dayjs.utc(current_time).format('YYYYMMDDHHmm');
        if (current_time === null)
          valid = this.basetime;
        const base = dayjs.utc(this.basetime).format('YYYYMMDDHHmm');
        //console.log(`base = ${base}, valid = ${valid}`);
        const results = this.itemIdsToShow.map((id) => this.updateGFSLayers(id, base, valid));
        await Promise.all(results);
      },

      onMapLocationFound: async function(evt) {
          let popupContent = `${evt.longitude.toFixed(2)}, ${evt.latitude.toFixed(2)}`;
          const elevation_src = `https://cyberjapandata2.gsi.go.jp/general/dem/scripts/getelevation.php?lon=${evt.longitude}&lat=${evt.latitude}`;
          const response = await fetch(elevation_src);
          const json = await response.text();
          const results = JSON.parse(json);
          popupContent += ` (${results.elevation}m)`;
          if (!this.locationMarker) {
            let location_icon = new L.Icon.Default();
            location_icon.options.iconSize = location_icon.options.iconSize.map(x => x * 0.5);
            location_icon.options.iconAnchor = [location_icon.options.iconSize[0]*0.5, location_icon.options.iconSize[1]];
            location_icon.options.shadowSize = [0,0];
            this.locationMarker = L.marker(evt.latlng, {icon: location_icon}).addTo(this.map);
            // this.locationMarker.bindPopup(evt.latlng.toString()).openPopup(); // mobile bug on leaflet version 1.7.1 ?
            this.locationPopup = L.popup({offset: L.point(location_icon.options.popupAnchor.map(x => x * 0.5))})
                  .setLatLng([evt.latitude, evt.longitude])
                  .setContent(popupContent)
            this.locationMarker.on('click', () => {
              this.locationPopup.openOn(this.map);
            });
          }
          else {
            this.locationMarker.setLatLng(evt.latlng);
            this.locationPopup.setContent(popupContent);
          }
      },

      onMapLocationError: function(evt) {
        evt;
        alert('現在地を取得できませんでした。');
      },

      onTimeDimensionLoading: function(evt) {
        console.log(`timeloading event: time = ${dayjs.utc(evt.time).format('YYYYMMDDHHmm')}`);
      },

      onTimeDimensionLoad: async function(evt) {
        const current_time = evt.time; //this.map.timeDimension.getCurrentTime();
        let valid = dayjs.utc(current_time).format('YYYYMMDDHHmm');
        if (current_time === null)
          valid = this.basetime;
        const base = dayjs.utc(this.basetime).format('YYYYMMDDHHmm');
        //console.log(`base = ${base}, valid = ${valid}`);
        const results = this.itemIdsToShow.map((id) => {
          return this.selectedModelId === 'Obs' ? this.updateJMALayers(id, base, valid)
                 : this.updateGFSLayers(id, base, valid);
        });
        await Promise.all(results);
        this.clearUnselectedLayers();
      },

      onAltSliderChange: function(altitude) {
        const windLayerId = this.itemIdsToShow.find((id) => id.includes('SKYWIND'));
        if (windLayerId === undefined || this.geoTiffLayer[windLayerId] === undefined)
          return;
        console.log('update wind altitude to', altitude);
        this.geoTiffLayer[windLayerId].updateAltitude(this.allLayers[windLayerId], altitude);
      }
    }
  }
</script>

<style scoped>
#map {
  z-index: 0;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

  .menu-scrollbar::-webkit-scrollbar{
    width: 10px;
  }
  .menu-scrollbar::webkit-scrollbar-track {
    background: #e6e6e6;
    border-left: 1px solid #dadada;
  }
  .menu-scrollbar::-webkit-scrollbar-thumb {
    background: #b0b0b0;
    border: solid 3px #e6e6e6;
    border-radius: 7px;
  }
  .menu-scrollbar::-webkit-scrollbar-thumb:hover {
    background: black;
  }

</style>

<style>
/* *** timedimension.control.css *** */
.leaflet-control-velocity {
  color: white;
}
.leaflet-bar-timecontrol{
  background-color: rgba(25, 25, 112, 0.6); /* rgba(46, 139, 87, 0.6); */
  color: white;
  height: 78px !important;
  width: var(--time-control-width) !important;
  margin: 0 8px 8px 0 !important;
  border: 0px solid #a5a5a5 !important;
}
.leaflet-bar-timecontrol .leaflet-control-timecontrol {
  height: 38px !important;
  line-height: 38px !important;
  border: 0px solid #a5a5a5;
  background-color: rgba(25, 25, 112, 0.4);
  border-width: 0 0px 0 0;
}
.leaflet-bar-timecontrol .leaflet-control-timecontrol:before {
    font-family: "Glyphicons Halflings";
    display: block;
}
.leaflet-bar-timecontrol .timecontrol-slider {
    position: absolute;
    width: var(--time-slider-width);
    cursor: auto;
    left: 40px;
    bottom: 0;
}
.leaflet-bar-timecontrol a.timecontrol-date,
.leaflet-bar-timecontrol a.timecontrol-date:hover {
  position: absolute;
  padding: 0 10px 0 10px;
  pointer-events: none; /* prevents all click */
  white-space: nowrap;
  top: 0px;
  left: 40px;
  min-width: var(--time-slider-width);
  width: var(--time-slider-width);
  font-size: x-large;
  color: white;
  text-shadow:2px 2px 0 black,-2px 2px 0 black,2px -2px 0 black,-2px -2px 0 black;
}
.leaflet-bar-timecontrol a.timecontrol-date.utc,
.leaflet-bar-timecontrol a.timecontrol-date.utc:hover {
    min-width: var(--time-slider-width);
}
.leaflet-bar-timecontrol a.timecontrol-date.loading,
.leaflet-bar-timecontrol a.timecontrol-date.loading:hover {
    background-color: #ffefa4;
}
.leaflet-bar-timecontrol .timecontrol-dateslider .slider {
    width: var(--time-sliderbar-width); /* updated */
}

.leaflet-bar-timecontrol .timecontrol-play,
.leaflet-bar-timecontrol .timecontrol-play:hover {
    position: absolute;
}
.leaflet-bar-timecontrol .timecontrol-play span {
    font-size: 10px;
}
.leaflet-bar-timecontrol a.timecontrol-play.loading {
    background-color: #ffefa4;
}

/**
* Slider styles
*/

.timecontrol-slider .slider {
    position: relative;
    height: 12px;
    border: 1px solid #a5a5a5;
    cursor: pointer;
}
.timecontrol-slider .slider.has-limits {
    margin-left: 15px;
    margin-right: 15px;
    background-color: #ddd;
}
.timecontrol-slider .slider.has-limits .range {
    position: absolute;
    height: 10px;
    background-color: #fff;
}
.timecontrol-slider .knob {
    background-color: #ddd !important;
    opacity: 1.0 !important;
}
.timecontrol-backward {
   position: absolute;
   top: 0;
   left: 0;
}
.timecontrol-forward {
   position: absolute;
   top: 0;
   right: 0;
}
.timecontrol-play {
   position: absolute;
   left: 0;
   bottom: 0;
}

.timecontrol-backward:before,
.timecontrol-forward:before,
.timecontrol-stop:before,
.timecontrol-play:before,
.timecontrol-loop:before {    
    width: 100%;
    text-align: center;
   background-color: #a5a5a5;
}

.timecontrol-play:before {
    position: absolute;
    content: "\e072";
}

.timecontrol-play.reverse:before {
    content: "\e072";
    -ms-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
}
.timecontrol-play.pause:before {
    content: "\e073";
}
.timecontrol-play.reverse.pause:before {
    -ms-transform: none;
    -webkit-transform: none;
    transform: none;
}

.timecontrol-stop:before {
    content: "\e074";
}
.timecontrol-forward:before {
    content: "\e075";
}
.timecontrol-backward:before {
    content: "\e071";
}
.timecontrol-loop:before {
    content: "\e030";
}

.leaflet-touch .leaflet-bar a {
  width: 40px;
}
.leaflet-touch .leaflet-bar-timecontrol .leaflet-control-timecontrol{
    height: 30px;
    line-height: 30px;
}
.leaflet-touch .timecontrol-slider .slider{
    margin: 12px 0px 0px 15px;
    background-color: rgb(0,0,0,0.3);
}

.leaflet-container.leaflet-interactive {
    cursor: pointer;
}

</style>
