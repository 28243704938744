import L from 'leaflet'
import { CogBase } from './CogBase';

export class WindyLayer {
  data_obj = undefined;
  element = undefined;
  pane_name = undefined;

  constructor(element) {
    this.element = element;
    this.pane_name = "animationWindPane";
    this.model = undefined;
    this.basetime = 0;
    this.validtime = 0;
    this.cog = new CogBase();

    /*
    L.Control.Velocity.include({  // Create subclass of L.velocityLayer.
      _superOnMouseMove: L.Control.Velocity.prototype._onMouseMove,
      _onMouseMove: function(e) {
        //console.log(e)
        this._superOnMouseMove(e);
        this._container.innerHTML = `${this._container.innerHTML.replace(/\.\d{2}/g, '')}`;
      }
    });
    */
  }

  async getGeotiffData(model, basetime, validtime, bounding_box, expected_resolution) {
    if (model     !== this.model || 
        basetime  !== this.basetime ||
        validtime !== this.validtime) {
      const url = `/tiff/${model}/${this.element}/${basetime}/${validtime}.tiff`;
      if (! await this.cog.fetch(url))
        return false;
    }
    const portion = await this.cog.findPortionByResolution(expected_resolution);
    const wnd = this.cog.geImagetWnd(portion, bounding_box);
    const portion_rasters = await this.cog.getPortionRasters(portion, wnd);
    const portionRes = this.cog.calcPortionResolution(portion);
    const wholeOrigin = this.cog.getWholeOrigin();
    const u_array = Array.from(portion_rasters[0])
    const v_array = Array.from(portion_rasters[1])
    this.data_obj = [
      {
        'header': {
          'parameterCategory': 1,
          'parameterNumber': 2,
          'lo1': wnd[0] * portionRes[0] + wholeOrigin[0],    // longitude of upper-left
          'la1': -1.0 * wnd[1] * portionRes[1] + wholeOrigin[1],    // latitude of upper-left 
          'dx' : portionRes[0],
          'dy' : portionRes[1],
          'nx' : portion_rasters.width,
          'ny' : portion_rasters.height,
          //'refTime': "2017-02-01 23:00:00",
        },
        'data': u_array,
      },
      {
        'header': {
          'parameterCategory': 1,
          'parameterNumber': 3,
          'lo1': wnd[0] * portionRes[0] + wholeOrigin[0],    // longitude of upper-left
          'la1': -1.0 * wnd[1] * portionRes[1] + wholeOrigin[1],    // latitude of upper-left 
          'dx' : portionRes[0],
          'dy' : portionRes[1],
          'nx' : portion_rasters.width,
          'ny' : portion_rasters.height,
          //'refTime': "2017-02-01 23:00:00",
        },
        'data': v_array,
      }
    ];
    this.model = model;
    this.basetime = basetime;
    this.validtime = validtime;
    return true;
  }

  async setColorRenderer(renderer) {
    this.pane_name = renderer.options.pane;
  }
  setLineLabelsRenderer(renderer) {
    renderer;
  }

  getColorScale() {
    return [
      '#fffff0',
      '#0096ff',
      '#faf500',
      '#ff9900',
      '#ff2800',
      '#b40068',
    ];
  }

  async createLayers() {
    var velocityLayer = await L.velocityLayer({
        displayValues: true,
        displayOptions: {
          // label prefix
          velocityType: "",
          // leaflet control position
          position: "bottomleft",
          // no data at cursor
          emptyString: "No velocity data",
          // see explanation below
          angleConvention: "meteoCW",
          // display cardinal direction alongside degrees
          showCardinal: false,
          // one of: ['m/s', 'k/h', 'kt']
          speedUnit: "m/s",
          // direction label prefix
          directionString: "風向",
          // speed label prefix
          speedString: "風速"
        },
        data: this.data_obj, // see demo/*.json, or wind-js-server for example data service
        // OPTIONAL
        minVelocity: 0, // used to align color scale
        maxVelocity: 30, // used to align color scale
        velocityScale: 0.008, // modifier for particle animations, arbitrarily defaults to 0.005
        colorScale: this.getColorScale(), // define your own array of hex/rgb colors
        onAdd: null, // callback function
        onRemove: null, // callback function
        opacity: 0.97, // layer opacity, default 0.97
        //particleMultiplier: 1/300,
        //particleAge: 30,

        // optional pane to add the layer, will be created if doesn't exist
        // leaflet v1+ only (falls back to overlayPane for < v1)
        paneName: this.pane_name,
      });
    return [velocityLayer];
  }

  async updateLayers(layer_group) {
    const current_layers = await layer_group.getLayers();
    if (current_layers.length == 0) {
      const new_layers = await this.createLayers();
      new_layers.forEach(layer => layer_group.addLayer(layer));
    }
    else {
      current_layers[0].setData(this.data_obj);
    }
  }
}