<template>
  <v-app class="v-app-full-height">
    <v-app-bar v-if="false"
      app
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-logo-dark.png"
          transition="scale-transition"
          width="40"
        />

        <v-img
          alt="Vuetify Name"
          class="shrink mt-1 hidden-sm-and-down"
          contain
          min-width="100"
          src="https://cdn.vuetifyjs.com/images/logos/vuetify-name-dark.png"
          width="100"
        />
      </div>

      <v-spacer></v-spacer>

      <v-btn
        href="https://github.com/vuetifyjs/vuetify/releases/latest"
        target="_blank"
        text
      >
        <span class="mr-2">Latest Release</span>
        <v-icon>mdi-open-in-new</v-icon>
      </v-btn>
    </v-app-bar>

    <v-main>
      <v-container>
      <router-view/>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
  import Vue from 'vue'

  export default Vue.extend({
    name: 'App',
    methods: {
      debounce: function(func, interval) {
        var timer;
        return function() {
          clearTimeout(timer);
          timer = setTimeout(function() {
            func();
          }, interval)
        }
      },
      setViewHeight: function() {
        const vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
      },
    },
    mounted() {
      this.setViewHeight();
      const debouncedSetHeight = this.debounce(this.setViewHeight, 50);
      window.addEventListener('resize', debouncedSetHeight);
      this.$once('destroyed', () => {
        window.removeEventListener('resize', debouncedSetHeight);
      })
    },
    data: () => ({
      //
    }),
  });
</script>
<style>
html {
  overflow: hidden !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

html::-webkit-scrollbar {
  width: 0;
  height: 0;
}
</style>
<style scoped>
  /* refer to https://github.com/vuetifyjs/vuetify/issues/11452 */
  /**
    Overwrite vuetify's default v-application--wrap min-height: 100vh;
    Due to the many problems with vh on mobile devices.
   */
  .v-app-full-height ::v-deep .v-application--wrap {
    min-height: 100vh;
    min-height: calc(var(--vh, 1vh) * 100);
  }
</style>