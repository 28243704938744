import  { GeotiffLayer } from './GeotiffLayer';

export class HGTLayer extends GeotiffLayer {
  createThresholdColor() {
    const interval = 60; // isolines every 60m
    const min_H = this.data_obj.raster[0].reduce((a, b) => a < b ? a : b);
    const max_H = this.data_obj.raster[0].reduce((a, b) => b < a ? a : b);
    const min_value = Math.floor(min_H / interval) * interval;
    const max_value = Math.ceil (max_H / interval) * interval;
    const num = parseInt((max_value - min_value) / interval) + 1;
    const thresholds = [...Array(num)].map((v, i) => min_value + i*interval);
    let colors = { };
    let coloring_height = undefined;
    if      (this.surface === 500)  coloring_height = 5880;
    else if (this.surface === 300)  coloring_height = 9720;
    if (coloring_height) {
      thresholds.forEach(val => {
        if (coloring_height <= val)
          colors[val] = '#ffd700';
      });
    }
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor.colors, 
      1, (value => { return value % 300 == 0 ? 2 : 1; }),
      (value => Math.round(value / 1)));
    return [color_layer, line_layer];
  }
}