import  { GeotiffLayer } from './GeotiffLayer';

export class SSILayer extends GeotiffLayer {
  createThresholdColor() {
    const colors = {
        '-5' : '#c7408e',
        '-3' : '#ff5e40',
        '-1' : '#ffb340',
         '0' : '#fff840',
         '1' : '#4071ff',
         '2' : '#59a9ff',
    };
    const thresholds = Object.keys(colors).sort((a, b) => parseInt(b) - parseInt(a)); // descending
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor, 0.5, 0.5);
    return [color_layer, line_layer];
  }
}