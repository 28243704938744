import L from 'leaflet'
import { contours as d3Contours } from 'd3-contour';      // BSD 3-Clause
import { merge } from 'd3-array';                         // BSD 3-Clause
import { CogBase } from './CogBase';

export class GeotiffLayer {

  constructor(element) {
    this.colorRenderer = undefined;
    this.lineLabelsRenderer = undefined;
    this.data_obj = undefined;
    this.model = undefined;
    this.element = element;
    this.surface = 0;
    this.basetime = 0;
    this.validtime = 0;
    this.f_scalingPixelValues = undefined;
    this.cog = new CogBase();
    const s = this.element.split('_');
    if (s.length == 2)
      this.surface = parseInt(s[1]);
  }

  async getGeotiffData(model, basetime, validtime, bounding_box, expected_resolution) {
    if (model     !== this.model || 
        basetime  !== this.basetime ||
        validtime !== this.validtime) {
    const url = `/tiff/${model}/${this.element}/${basetime}/${validtime}.tiff`;
    if (! await this.cog.fetch(url))
      return false;
    }
    const portion = await this.cog.findPortionByResolution(expected_resolution);
    const wnd = this.cog.geImagetWnd(portion, bounding_box);
    const portion_rasters = await this.cog.getPortionRasters(portion, wnd);
    const portionRes = this.cog.calcPortionResolution(portion);
    const wholeOrigin = this.cog.getWholeOrigin();
    const geoTransform = [
      wnd[0] * portionRes[0] + wholeOrigin[0],    // longitude of upper-left 
      portionRes[0],
      0,
      -1.0 * wnd[1] * portionRes[1] + wholeOrigin[1],    // latitude of upper-left 
      0,
      -1.0 * portionRes[1],
    ];
    this.data_obj  = {
      raster: portion_rasters,
      geotransform: geoTransform,
    };
    this.model = model;
    this.basetime = basetime;
    this.validtime = validtime;
    return true;
  }

  setColorRenderer(renderer) {
    this.colorRenderer = renderer;
  }
  setLineLabelsRenderer(renderer) {
    this.lineLabelsRenderer = renderer;
  }

  createThresholdColor() {
    const thresholds = [ ];
    const colors = { };
    return { thresholds, colors };
  }

  createLayers() {
    const thresholdColor = this.createThresholdColor();
    const polygon = this.createPolygon(thresholdColor.thresholds);
    const color_layer = this.createColorLayer(polygon, thresholdColor.colors);
    const line_layer = this.createLineLayer(polygon, thresholdColor.colors);
    return [color_layer, line_layer];
  }

  Round(value, decimal_place) {
    const base = Math.pow(10, decimal_place);
    return Math.round(value * base) / base;
  }

  createPolygon(thresholds) {
    const width = this.data_obj.raster.width;
    const height = this.data_obj.raster.height;
    const geoTransform = this.data_obj.geotransform;
    var contour_f = d3Contours()
        .size([width, height])
        .smooth(true)
        .thresholds(thresholds)
        .nodataValue(9999);
    let contours;
    if (this.f_scalingPixelValues) {
      const raster = this.data_obj.raster[0].map(x => this.f_scalingPixelValues(x));
      contours = contour_f(raster);
    }
    else {
      contours = contour_f(this.data_obj.raster[0]);
    }
    return this.createPolygonGeojsonFeature(contours, width, height, geoTransform);
  }

  createPolygonGeojsonFeature(contours, pix_width, pix_height, geoTransform) {
    const upper_left_long = geoTransform[0];
    const upper_left_lat = geoTransform[3];
    const diff_long = pix_width * geoTransform[1];
    const diff_lat = pix_height * geoTransform[5];
    const upper_left  = [upper_left_long, upper_left_lat];
    const lower_right = [upper_left_long + diff_long, upper_left_lat + diff_lat];
    let features = [];
    for (let contour of contours) {
      // converts [x, y] in pixel coordinates to [longitude, latitude]
      let coords = merge(contour.coordinates.map(polygon => {
                    return polygon.map(ring => {
                      return ring.map(point => {
                        return [upper_left_long + point[0] / pix_width  * diff_long,
                                upper_left_lat  + point[1] / pix_height * diff_lat];
                      });
                    });
                  }));
      if (coords.length === 0 || coords[0].length === 0)  continue;
      features.push({
        type: "Feature",
        geometry: { type: "Polygon", coordinates: coords },
        properties: [{ value: contour.value, lower_limit_rings: coords.length }]
      });
    }
    // Add polygon(ring) holes of next value.
    for (let i = 0; i < features.length - 1; ++i) {
      for (let ring of features[i+1].geometry.coordinates) {
        features[i].geometry.coordinates.push(ring); 
      }
    }
    return { type: "FeatureCollection", features: features, properties: { upper_left, lower_right } };
  }

  createLineGeojsonFeature(contours) {
    /*
    const min_long = Math.max(1, contours.properties.upper_left[0]);
    const max_lat  = Math.max(89, contours.properties.upper_left[1]);
    const max_long = Math.min(359, contours.properties.lower_right[0]);
    const min_lat  = Math.min(-89, contours.properties.lower_right[1]);
    */
    const min_long = contours.properties.upper_left[0];
    const max_lat  = contours.properties.upper_left[1];
    const max_long = contours.properties.lower_right[0];
    const min_lat  = contours.properties.lower_right[1];
    let features = [];
    contours.features.forEach(feature => {
      let coords = feature.geometry.coordinates;
      let coords_without_boundary = [];
      const cnt = feature.properties[0].lower_limit_rings;
      const rings_for_drawline = cnt ? (cnt <= coords.length ? cnt : coords.length)
                                     : coords.length
      for (let i = 0; i < rings_for_drawline; ++i) {
        let ring = coords[i];
        let new_ring = [];
        let prev = [undefined, undefined];
        ring.forEach(point => {
          if ((prev[0]  <= min_long && point[0] <= min_long) ||
              (max_long <= prev[0]  && max_long <= point[0]) ||
              (prev[1]  <= min_lat  && point[1] <= min_lat)  ||
              (max_lat  <= prev[1]  && max_lat  <= point[1])) {
            if (2 <= new_ring.length) {
              coords_without_boundary.push(new_ring);
            }
            new_ring = [];
          }
          if (min_long < point[0] && point[0] < max_long &&
              min_lat  < point[1] && point[1] < max_lat) {
            new_ring.push(point);
          }
          prev = point;
        });
        if (2 <= new_ring.length) {
          coords_without_boundary.push(new_ring);
        }
      }
      features.push(
        {
          type: "Feature", 
          geometry: {type: "MultiLineString", coordinates: coords_without_boundary}, 
          properties: [{ value: feature.properties[0].value }],
        }
      );
    });
    return {type: "FeatureCollection", features: features};
  }

  createColorLayer(polygon, colors={}) {
    if (!polygon) return;
    return L.geoJson(polygon, {
        renderer: this.colorRenderer,
        style: function(feature) {
          let color = colors[feature.properties[0].value];
          let opacity = 1;
          const rgba_pattern = /^(#[0-9a-f]{6})([0-9a-f]{2})$/ig;
          const is_rgba = rgba_pattern.exec(color);
          if (is_rgba) {
            color = is_rgba[1];
            opacity = parseInt(is_rgba[2], 16) / 255;
          }
          if (!color || color === 'transparent') {
            color = '#000000';
            opacity = 0;
          }
          return {
            weight: 0,
            opacity: 0,
            fillColor: color,
            fillOpacity: opacity,
          }
        }.bind(this)
      });
  }

  createLineLayer(polygon, colors, 
                      line_opacity=1, line_weight=1, f_label_convert=undefined) {
    if (!polygon) return;
    const lines = this.createLineGeojsonFeature(polygon);
    let color = undefined;
    let weight = 1;
    return L.geoJson(lines, {
      renderer: this.lineLabelsRenderer,
      style: function(feature) {
        color = typeof colors === 'string' ? colors : colors[feature.properties[0].value];
        if (!color || color === 'transparent')  color = '#a0a0a0';
        if (typeof line_weight === 'function')
          weight = line_weight(feature.properties[0].value);
        else
          weight = line_weight;
        return {
          color: color,
          weight: weight,
          opacity: line_opacity,
        }
      }.bind(this),
      onEachFeature: (feature, layer) => {
        layer;
        if ( !feature.properties[0] ) return;
        const label = f_label_convert ? 
          f_label_convert(feature.properties[0].value).toString() : 
          feature.properties[0].value.toString();
        feature.properties = {
          contour: label,
          color: color,
        }
      }
    });
  }

  async updateLayers(layer_group) {
    const new_layers = this.createLayers();
    await layer_group.clearLayers();
    new_layers.forEach(layer => layer_group.addLayer(layer));
  }
}
